import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { deleteUser, fetchUsers } from '../../../api/services/UserServices';
import { ERROR, SUCCESS } from '../../../components/general/app-toast/AppToast';
import UseAppTable from '../../../helpers/hooks/use-app-table';
import UseAppToast from '../../../helpers/hooks/use-app-toast';
import { getUserFromLS } from '../../../helpers/Storage';
import { UPDATE_PROP } from '../../../store/ActionTypes';
import { UserColumn } from './UserColumn';
import { extractErrorMessage } from '../../../helpers/Common';

export const INITIAL_STATE = {
  saveLoading: false,
  showFilterModal: false,
  filters: {
    email: { sign: 'contains', value: '' },
    name: { sign: 'contains', value: '' },
  },
};
const Logic = (dispatch, state, prop) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { addToast } = UseAppToast();
  const { data, setData, error } = UseAppTable({
    fetchTableData: fetchUsers,
  });
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const HandleCloseDelete = () => {
    setShowDeleteAlert(false);
  };

  const bulkDelete = (selectedRow) => {
    setShowDeleteAlert(true);

    setData((prevState) => {
      return {
        ...prevState,
        selectedRows: selectedRow,
      };
    });
  };

  // const user = useSelector((state) => state.user);
  const user = getUserFromLS();

  const handleDelete = () => {
    const selectedArray = data.selectedRows.map((d) => d.id);
    // update modal loading
    dispatch({
      payload: [{ type: UPDATE_PROP, prop: 'deleteLoading', value: true }],
    });
    deleteUser(
      (res) => {
        let arrResults = [...data.results];
        selectedArray.map((d) => {
          const index = arrResults.findIndex((x) => {
            console.log(x.id);
            return x.id === d;
          });
          arrResults.splice(index, 1);
        });
        setData((prevState) => {
          let newData = {
            results: [...arrResults],
            total: prevState.total - 1,
          };
          if (arrResults.length == 0) {
            newData = {
              ...newData,
              params: {
                ...prevState.params,
                page: prevState.params.page - 1,
              },
            };
          }
          return {
            ...prevState,
            ...newData,
          };
        });
        HandleCloseDelete();
        addToast({
          type: SUCCESS,
          description: 'User is deleted successfully',
          title: 'Success',
        });
      },
      (error) => {
        addToast({
          type: ERROR,
          description: extractErrorMessage(error),
          title: 'Error',
        });
      },
      () => {
        // update modal loading
        dispatch({
          payload: [{ type: UPDATE_PROP, prop: 'deleteLoading', value: false }],
        });
      },
      { ids: selectedArray }
    );
  };
  const handleShowConfimDelete = (id) => {
    dispatch({
      payload: [
        {
          type: UPDATE_PROP,
          prop: `showDeleteAlert`,
          value: true,
        },
        {
          type: UPDATE_PROP,
          prop: `deletedID`,
          value: id,
        },
      ],
    });
  };
//   const handleCloseDelete = () => {
//     dispatch({
//       payload: [
//         {
//           type: UPDATE_PROP,
//           prop: `showDeleteAlert`,
//           value: false,
//         },
//         {
//           type: UPDATE_PROP,
//           prop: `deletedID`,
//           value: null,
//         },
//       ],
//     });
//   };

  const actions = () => {
    return { delete: handleShowConfimDelete, navigate , bulkDelete: bulkDelete};
  };
  let columns = useMemo(
    () => UserColumn(actions(), user?.email, t),
    [i18n.language],
  );
  return {
    actions: {
      handleDelete,
      setData,
      navigate,
      setShowDeleteAlert,
      HandleCloseDelete,
    },
    columns,
    data,
    error,
    showDeleteAlert,
  };
};

export default Logic;
