import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronCircleDown,
  faChevronCircleRight,
  faEllipsisV,
  faSort,
} from '@fortawesome/free-solid-svg-icons';
import AppButton from '../../../../../../../../components/general/app-button/AppButton';
import styles from './Section.module.css';

import React, { useRef, useState } from 'react';
import AppTooltip from '../../../../../../../../components/general/app-tooltip/AppTooltip';
import AddNewItem from '../add-new-item/AddNewItem';
import Card from '../../../../../../../../components/general/card/Card';
import SubSection from '../sub-section/SubSection';
import { Container, Draggable } from '@edorivai/react-smooth-dnd';

import globalLogic from '../../../../globalLogic';
import ElementMaker from '../element-maker/ElementMaker';
import { useTranslation } from 'react-i18next';
import { UPDATE_PROP } from '../../../../../../../../store/ActionTypes';

const Section = ({ section, sectionIndex, updatedTranslations }) => {
  const {
    // state,
    addSubSection,
    deleteSection,
    onSubsectionDrop,
    toggleSection,
    getPayload,
    updateSectionName,
    updateSectionTranslations,
    updateState,
  } = globalLogic();
  const { t } = useTranslation();
  // const [currentField, setCurrentField] = useState({});
  // const translationModalRef = useRef();
  // const handleTranslation = (translations) => {
  //     console.log(translations);
  //   };

  return (
    <Card
      className={`${styles.cardSection} ${
        section.collapsed && styles.collapsedCardSection
      }`}
      key={section.id}
    >
      <div className={styles.section}>
        <div className={styles.sectionText}>
          <FontAwesomeIcon
            icon={faSort}
            color={'var(--lightGrey)'}
            className="column-drag-handle"
          />
          <AppButton
            className="buttonIcon"
            onClick={() => {
              console.log('collapsed');
              toggleSection(sectionIndex);
            }}
            icon={
              section.collapsed ? (
                <FontAwesomeIcon
                  icon={faChevronCircleRight}
                  color={'var(--primary)'}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faChevronCircleDown}
                  color={'var(--primary)'}
                />
              )
            }
            style={{
              borderRadius: 0,
              color: 'black',
            }}
          />

          {/* <p className={styles.sectionText}>{section.name}</p> */}
          <ElementMaker
            columnName="sectionName"
            translations={section.translations?.sectionName}
            handleChange={(updatedTranslations) => {
              console.log("Updated translations in ElementMaker:", updatedTranslations); 
              updateSectionName(
                sectionIndex,
                updatedTranslations[t('langCode')],
                updatedTranslations
              );
            }}
            value={section.translations?.sectionName?.[t('langCode')] || section.name}
          />
        </div>

        <AppTooltip
          placement="bottom-end"
          buttonValue={
            <FontAwesomeIcon
              icon={faEllipsisV}
              style={{ alignSelf: 'center', color: 'grey' }}
            />
          }
          buttonStyle={{ padding: '2px 3px', fontSize: 15 }}
          renderProp={({ setShow }) => (
            <div className={styles.tooltipContent}>
              <AppButton
                className="buttonIcon"
                onClick={() => {
                  deleteSection(sectionIndex);
                  setShow(false);
                }}
                style={{
                  borderRadius: 0,
                  color: 'black',
                }}
                value={t('delete')}
              />
            </div>
          )}
        />
      </div>
      {!section.collapsed ? (
        <>
          <div className={styles.subSectionContainer}>
            <div className={styles.subSectionContent}>
              <Container
                groupName="col"
                onDragStart={(e) => console.log('drag started', e)}
                onDragEnd={(e) => console.log('drag end', e)}
                // onDrop={(e) => onDrop(`${section.id}`, e)}
                onDrop={(e) => onSubsectionDrop(section.id, e)}
                getChildPayload={(index) =>
                  getPayload(section.subsections[index])
                }
                dragHandleSelector=".column-drag-handle-subsection"
                dragClass="card-ghost"
                dropClass="card-ghost-drop"
                // orientation="vertical"
                className="card-container"
                // onDragEnter={() => {
                //   console.log("drag enter:", column.id);
                // }}
                // onDragLeave={() => {
                //   console.log("drag leave:", column.id);
                // }}
                // onDropReady={(p) => console.log("Drop ready: ", p)}
                dropPlaceholder={{
                  animationDuration: 150,
                  showOnTop: true,
                  className: styles.droppreview,
                }}
                dropPlaceholderAnimationDuration={200}
              >
                {section.subsections.map((item, subsectionindex) => (
                  <Draggable key={item.id}>
                    <SubSection
                      itemInfo={item}
                      key={item.id}
                      subsectionindex={subsectionindex}
                      sectionindex={sectionIndex}
                      // addQuestion={addQuestion}
                      subSectionId={item.id}
                      sectionId={section.id}
                      // setDraggedItem={setDraggedItem}
                      // state={state}
                      // deleteSubSection={deleteSubSection}
                      // deleteQuestion={deleteQuestion}
                      // toggleSubSection={toggleSubSection}
                      // setSelectedQuestion={setSelectedQuestion}
                    />
                  </Draggable>
                ))}
              </Container>
            </div>
            <AddNewItem
              toggleButtonText={t('addSubSection')}
              onAdd={(text) => {
                addSubSection(sectionIndex, text);
              }}
            />
          </div>
        </>
      ) : null}
    </Card>
  );
};

export default Section;