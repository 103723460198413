import { t } from 'i18next';
export const requiredValidator = (value, field) => {
    if (
        !value ||
        (typeof value === "object" && Object.keys(value).length == 0) ||
        (typeof value === "string" && !value.trim())
    ) {
        return t('required');
    }

    return "";
};
export const emailValidator = (value, field) => {
    if (
        !new RegExp(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ).test(value)
    ) {
        return t('emailInvalidEmail');
    }
    return "";
};
export const patternValidator = (pattern, value, field) => {
    if (!new RegExp(pattern).test(value)) {
        return t('invalidFormat');
    }
    return "";
};
export const maxLengthValidator = (length, value, field) => {
    if (value.length > length) {
        return t('maxLength');
    }
    return "";
};
export const minLengthValidator = (length, value, field) => {
    if (value.length < length) {
        return t('minLength');
    }
    return "";
};
export const numericValidator = (value, field) => {
    if (isNaN(parseFloat(value))) {
        return t('notValidNumber');
    }
    return "";
};
export const minValidator = (min = 0, value, field) => {
    if (value < min) {
        return t('minValue');
    }
    return "";
};
export const maxValidator = (max = 0, value, field) => {
    if (value > max) {
        return t('maxValue');
    }
    return "";
};
export const customValidate = (fn, value, field) => {
    if (fn(value)) {
        return t('customInvalid');
    }
    return "";
};

export const imageValidator = (image, field, required = false) => {
    if (required) {
        if (!image) {
            return t('requiredImage');
        }
    }

    if (typeof image === "object" && image != null) {
        if (!image.name.match(/\.(jpg|jpeg|png|gif)$/)) {
            return t('invalidImage');
        }
        if (image.size < 10000) {
            return t('minFileSize');
        }
        if (image.size > 500000) {
            return t('maxFileSize');
        }
    }

    return "";
};
export const exactValidator = (value1, field1, value2, field2) => {
    if (value1 != value2) {
        return t('doesNotMatch');
    }
    return "";
};