import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faEdit, faRedo } from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import AppButton from "../../components/general/app-button/AppButton";
export const subscriptionColumns = (
    actions,
    translate = (term) => {
        return term;
    }
) => {
    return [
        {
            Header: `${translate("plan")}`,
            accessor: "plan_name",
        },
        {
            Header: `${translate("status")}`,
            accessor: "status",
        },
        {
            Header: `${translate("users")}`,
            accessor: "number_of_users",
            Cell: (props) => {
                return props.row.original.number_of_users === null
                    ? "∞"
                    : props.row.original.number_of_users;
            },
        },
        // {
        //     Header: "used Users",
        //     accessor: "used_users_count",
        // },
        {
            Header: `${translate("expiryDate")}`,
            accessor: "subscription_end_date",
        },
        {
            Header: `${translate("actions")}`,
            accessor: "actions",
            Cell: (props) => {
                var diff,
                    aDay = 86400000;
                    console.log("display data", props.row.original.used_users_count)
                diff = Math.floor(
                    (new Date(props.row.original.subscription_end_date) - new Date()) / aDay
                );
                return (
                    
                    props.row.original.status === "active" &&
                    diff <= 0 && (
                        <div className="flex justify-center">
                            <AppButton
                                classes="buttonIcon"
                                onClick={() => {
                                    Storage.setItem("previousUsers", props.row.original.number_of_users);
                                    // Storage.setItem("usedUser", props.row.original.used_users_count);
                                    actions.navigate(`/admin/plans`, {
                                        state: { 
                                            usersNumber: props.row.original.number_of_users,
                                            usedUsersCount: props.row.original.used_users_count 
                                        }
                                    });
                                    
                                  }}
                                  
                                value="Renew"
                                style={{
                                    color:
                                        props.row.original.status === "active" && diff <= 0
                                            ? "#2e5bff"
                                            : "#c7c3c3",
                                }}
                                icon={
                                    <FontAwesomeIcon icon={faRedo} style={{ marginRight: "5px" }} />
                                }
                                disabled={!(props.row.original.status === "active" && diff <= 0)}
                            />
                        </div>
                    )
                );
            },
        },
    ];
};
