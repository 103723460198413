import AppInput from "../../../../../components/general/app-input/AppInput";
import AppModal from "../../../../../components/general/app-modal/AppModal";
import Card from "../../../../../components/general/card/Card";
import Label from "../../../../../components/general/label/Label";
import SimpleTable from "../../../../../components/general/simple-table/SimpleTable";
import Spacer from "../../../../../components/general/spacer/Spacer";
import styles from "./ActionPlanPopup.module.css";
import Chat from "./Chat/chat";

const ActionPlanPopup = ({
  show,
  onHide,
  data,
  columns,
  progData,
  actionPlanMessages,
  submitMessage,
  submitAttachments,
  progDataLoading,
  getActionPlanProgress,
}) => {
  let createdAt = Date.parse(data.created_at); 
  let updatedAt = data.status ? Date.parse(data.updated_at) : Date.now(); 
  
  let diff = Math.abs(updatedAt - createdAt); 
  
  const diffInMinutes = Math.floor(diff / (1000 * 60)) % 60;
  const diffInHours = Math.floor(diff / (1000 * 60 * 60)) % 24;
  const diffInDays = Math.floor(diff / (1000 * 60 * 60 * 24));
  
  const duration = `${diffInDays} D ${diffInHours} H ${diffInMinutes} M`;
  console.log(duration);
  
  
  return (
    <AppModal
      show={show}
      onHide={onHide}
      fullscreen={true}
      closeBtn="true"
      footer={
        <>
          {/* <AppButton
                        style={{ padding: "1px 12px" }}
                        onClick={onHide}
                        value={t("cancel")}
                    />
                    <AppButton
                        className="primaryButton"
                        style={{ padding: "1px 12px" }}
                        onClick={onSave}
                        value={t("save")}
                    /> */}
        </>
      }
      bodyStyle={{ paddingTop: 0 }}
      footerStyle={{ border: 0, paddingTop: 0 }}
      headerStyle={{ border: 0, paddingBottom: 0 }}
    >
      <div style={{ height: "100%" }}>
        <div className={styles.container}>
          <div className={styles.contentSection}>
            <Card className={styles.defaultCard}>
              <div className={styles.rowSection}>
                <span className={styles.title} style={{ flex: 1 }}>
                  {data.audit_name}
                </span>
                <Label
                  name="Resolution Time"
                  className={styles.label}
                  classText={styles.labelText}
                  style={{
                    minWidth: "10%",
                    display: "flex",
                    flexDirection: "row",
                    width: "auto",
                  }}
                >
                  <span style={{ fontSize: 30 }}>{duration}</span>
                </Label>
              </div>
              {/* <Spacer height={25} />
                            <div className={styles.rowSection}>
                                <AppButton className="primaryButton" value="Forward" />
                                <Spacer width={10} />
                                <AppButton className="primaryButton" value="Close" />
                            </div> */}
              {data?.action_taken_text && (
                <>
                  <Spacer height={25} />
                  <div className={styles.rowSection}>
                    <Label
                      name="Closing Remarks"
                      className={styles.label}
                      classText={styles.labelText}
                    >
                      <AppInput
                        type="text"
                        className={styles.input}
                        textarea={true}
                        value={data?.action_taken_text}
                        // onChange={(e) => onChange("first_name", e.target.value)}
                        disabled={true}
                        // onBlur={(e) => onBlur("first_name")}
                      />
                    </Label>
                  </div>
                </>
              )}
              <Spacer height={25} />
              <div className={styles.rowSection}>
                <Label
                  name="Location"
                  className={styles.label}
                  classText={styles.labelText}
                >
                  <AppInput
                    type="text"
                    className={styles.input}
                    value={data?.location}
                    // onChange={(e) => onChange("first_name", e.target.value)}
                    disabled={true}
                    // onBlur={(e) => onBlur("first_name")}
                  />
                </Label>
                <Spacer width={75} />
                <Label
                  name="Auditor"
                  className={styles.label}
                  classText={styles.labelText}
                >
                  <AppInput
                    type="text"
                    className={styles.input}
                    value={data?.auditor_name}
                    // onChange={(e) => onChange("first_name", e.target.value)}
                    disabled={true}
                    // onBlur={(e) => onBlur("first_name")}
                  />
                </Label>
                <Spacer width={75} />
                <Label
                  name="Creation Date"
                  className={styles.label}
                  classText={styles.labelText}
                >
                  <AppInput
                    type="text"
                    className={styles.input}
                    value={data?.created_at ? data.created_at.replace("T", " ").split(".")[0] : ""}
                    // value={
                    //   new Date(data?.created_at).toLocaleDateString("fr-CA") +
                    //   " " +
                    //   new Date(data?.created_at).toLocaleTimeString("it-IT")
                    // }
                    // onChange={(e) => onChange("first_name", e.target.value)}
                    disabled={true}
                    // onBlur={(e) => onBlur("first_name")}
                  />
                </Label>
              </div>
              <Spacer height={25} />
              <div className={styles.rowSection}>
                <Label
                  name="Description"
                  className={styles.label}
                  classText={styles.labelText}
                >
                  <AppInput
                    type="text"
                    className={styles.input}
                    textarea={true}
                    value={data?.action_text}
                    // onChange={(e) => onChange("first_name", e.target.value)}
                    disabled={true}
                    // onBlur={(e) => onBlur("first_name")}
                  />
                </Label>
                <Spacer width={75} />
                <Label
                  name="Reason of Trigger"
                  className={styles.label}
                  classText={styles.labelText}
                >
                  <AppInput
                    type="text"
                    className={styles.input}
                    textarea={true}
                    value={data?.reason_of_trigger}
                    // onChange={(e) => onChange("first_name", e.target.value)}
                    disabled={true}
                    // onBlur={(e) => onBlur("first_name")}
                  />
                </Label>
              </div>
              <Spacer height={25} />
              <div className={styles.rowSection}>
                <Label
                  name="Assigned"
                  className={styles.label}
                  classText={styles.labelText}
                >
                  <AppInput
                    type="text"
                    className={styles.input}
                    value={data?.assignee_id?.name}
                    // onChange={(e) => onChange("first_name", e.target.value)}
                    disabled={true}
                    // onBlur={(e) => onBlur("first_name")}
                  />
                </Label>
                <Spacer width={75} />
                <Label
                  name="Due Date"
                  className={styles.label}
                  classText={styles.labelText}
                >
                  <AppInput
                    type="text"
                    className={styles.input}
                    // value={form.first_name}
                    // onChange={(e) => onChange("first_name", e.target.value)}
                    disabled={true}
                    // onBlur={(e) => onBlur("first_name")}
                  />
                </Label>

                <Spacer width={75} />
                <Label
                  name="Priority"
                  className={styles.label}
                  classText={styles.labelText}
                >
                  <AppInput
                    type="text"
                    className={styles.input}
                    value={data?.priority}
                    // onChange={(e) => onChange("first_name", e.target.value)}
                    disabled={true}
                    // onBlur={(e) => onBlur("first_name")}
                  />
                </Label>
                <Spacer width={75} />
                <Label
                  name="Status"
                  className={styles.label}
                  classText={styles.labelText}
                >
                  <AppInput
                    type="text"
                    className={styles.input}
                    value={data?.status}
                    // onChange={(e) => onChange("first_name", e.target.value)}
                    disabled={true}
                    // onBlur={(e) => onBlur("first_name")}
                  />
                </Label>
              </div>
              <Spacer height={25} />
              <div>
                {progData?.length}
                <SimpleTable
                  data={progData?.data ? progData.data : []}
                  columns={columns}
                  total={progData?.total}
                  getData={getActionPlanProgress}
                  // customFilters={state.filters}
                  // setCustomFilters={setFilters}
                  // customSorting={state.sorting}
                  // setCustomSorting={setSorting}
                  loading={progDataLoading}
                  showGoToPage={false}
                  showPageSizeOptions={false}
                  pageSizeOptions={[10]}
                />
              </div>
            </Card>
          </div>
          <div className={styles.commentSection}>
            <Card className={styles.defaultCard}>
              <Chat
                actionPlanMessages={actionPlanMessages?.data}
                submitMessage={submitMessage}
                submitAttachments={submitAttachments}
                actionPlanId={data?.id}
              />
            </Card>
          </div>
        </div>
      </div>
    </AppModal>
  );
};

export default ActionPlanPopup;
