import {
  faCamera,
  faCommentDots,
  faEquals,
  faImage,
  faNotEqual,
  faSquare,
  faTimes,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {
  useCallback,
  useState,
  useFocus,
  useRef,
  useEffect,
} from 'react';
import AppButton from '../../../../../../../../components/general/app-button/AppButton';
import styles from './ResponseOption.module.css';
import AppInput from '../../../../../../../../components/general/app-input/AppInput';
import { ColorPicker, useColor, Color, toColor } from 'react-color-palette';
import 'react-color-palette/lib/css/styles.css';
import Label from '../../../../../../../../components/general/label/Label';
import AppTooltip from '../../../../../../../../components/general/app-tooltip/AppTooltip';
import {
  DELETE_PROP,
  UPDATE_PROP,
} from '../../../../../../../../store/ActionTypes';
import { debounce } from '../../../../../../../../helpers/Common';
import { useTranslation } from 'react-i18next';
import TranslationModal from '../../../../../../../../components/general/translation-modal/TranslationModal';
import AppSelect from '../../../../../../../../components/general/app-select/AppSelect';
import { filterSelectStyles } from '../../Constants';
import SwitchButton from '../../../../../../../../components/general/switch-button/SwitchButton';
import Spacer from '../../../../../../../../components/general/spacer/Spacer';
import ReactSwitch from 'react-switch';
// import { debounce } from "../../../../../../../../helpers/Common";
const ResponseOption = ({
  response,
  index,
  updateQuestion,
  type,
  operators,
}) => {
  const { t } = useTranslation();
  const translationModalRef = useRef();
  const [currentField, setCurrentField] = useState({});
  const [color, setColor] = useColor('hex', response.color);
  const onchange = (e, prop) => {
    updateQuestion([
      {
        type: UPDATE_PROP,
        prop: `response.${index}.${prop}`,
        value: e,
      },
    ]);
  };

  // const changeResponse = useCallback(debounce(onchange, 900), []);
  return (
    <div className={styles.reponse}>
      <div className={styles.responseInput}>
        <Label className={styles.label}>
          <AppInput
            type="text"
            className={styles.input}
            onChange={(e) => {
              const updatedValue = e.target.value;
              console.log('Input value changed:', updatedValue);

              // Update the label dynamically based on the current language
              const langCode = t('langCode');

              let updatedTranslations = {
                ...response.translations,
                label: {
                  ...response.translations?.label,
                  [langCode]: updatedValue, // Dynamically update the translation for the current language
                },
              };

              onchange(updatedValue, 'label');
              updateQuestion([
                {
                  type: UPDATE_PROP,
                  prop: `response.${index}.translations`,
                  value: updatedTranslations,
                },
              ]);

              console.log('Updated translations:', updatedTranslations);
            }}
            value={
              response.translations?.label?.[t('langCode')] || response.label
            } // Display the translation for the current language
            onTranslation={() => {
              const translationKey = 'label';
              setCurrentField({
                column: translationKey,
                translations: response.translations?.[translationKey],
              });

              translationModalRef?.current?.toggleModal();
            }}
          />
        </Label>

        <AppTooltip
          placement="bottom-end"
          buttonValue={
            <FontAwesomeIcon
              icon={faSquare}
              style={{
                alignSelf: 'center',
                color: response.color,
                width: '20px',
                height: '20px',
                boxShadow: ' 0 2px 3px rgb(0 0 0 / 35%) !important',
                border: '1px solid rgba(0, 0, 0, 0.15)',
                borderRadius: '5px',
              }}
            />
          }
          renderProp={({ setShow }) => (
            <div className={styles.tooltipContent}>
              <ColorPicker
                width={300}
                height={150}
                color={color}
                onChange={(e) => {
                  console.log(e);
                  setColor(e);
                  updateQuestion([
                    {
                      type: UPDATE_PROP,
                      prop: `response.${index}.color`,
                      value: e.hex,
                    },
                  ]);
                }}
                hideHSV
                hideRGB
                // dark
              />
            </div>
          )}
        />
      </div>
      {/* Condition if the type is "number" */}
      {type == 'number' && (
        <div
          className={styles.responseAction}
          style={{
            flexDirection: 'row',
            alignItems: 'flex-start',
            marginTop: '5px',
          }}
        >
          {/* Operator dropdown */}
          <Label
            name={t('operator')}
            className={styles.label}
            style={{ flex: '1' }}
            innerStyle={{ backgroundColor: '#f9f9f9' }}
          >
            <AppSelect
              options={operators.map((element) => ({
                value: `${element}`,
                label: `${element}`,
              }))}
              styles={filterSelectStyles}
              value={response.operator}
              onChange={(e) => {
                onchange(e, 'operator');
              }}
            />
          </Label>
          <Spacer width="10px" />
          {/* Value inputs */}
          {response.operator?.value != undefined && (
            <div style={{ flex: '1' }}>
              {/* Value 1 Input and Switch */}
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Label
                  name={t('value')}
                  className={styles.label}
                  style={{ flex: '2' }}
                  innerStyle={{ backgroundColor: '#f9f9f9' }}
                >
                  <AppInput
                    type="number"
                    className={styles.input}
                    style={{ height: '21px' }}
                    onChange={(e) => {
                      onchange(e.target.value, 'value');
                    }}
                    value={response.value}
                  />
                </Label>

                {response.operator?.value != 'equalTo' &&
                  response.operator?.value != 'notEqualTo' && (
                    <>
                      <Spacer width="10px" />
                      <ReactSwitch
                        onChange={() => {
                          onchange(!response.valueInclusive, 'valueInclusive');
                        }}
                        checked={response.valueInclusive}
                        height={20}
                        width={40}
                        handleDiameter={20}
                        onColor="#5F9EA0"
                        offColor="#5F9EA0"
                        uncheckedIcon={
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              height: '100%',
                              fontSize: 10,
                            }}
                          >
                            <FontAwesomeIcon color="white" icon={faNotEqual} />
                          </div>
                        }
                        checkedIcon={
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              height: '100%',
                              fontSize: 10,
                            }}
                          >
                            <FontAwesomeIcon color="white" icon={faEquals} />
                          </div>
                        }
                      />
                    </>
                  )}
              </div>
              {/* Value 2 Input and Switch */}
              {(response.operator?.value == 'between' ||
                response.operator?.value == 'notBetween') && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '12px',
                  }}
                >
                  <Label
                    name={t('value2')}
                    className={styles.label}
                    style={{ flex: '2' }}
                    innerStyle={{ backgroundColor: '#f9f9f9' }}
                  >
                    <AppInput
                      type="number"
                      className={styles.input}
                      onChange={(e) => {
                        onchange(e.target.value, 'value2');
                      }}
                      style={{ height: '21px' }}
                      value={response.value2}
                    />
                  </Label>
                  <Spacer width="10px" />
                  <ReactSwitch
                    onChange={() => {
                      onchange(!response.value2Inclusive, 'value2Inclusive');
                    }}
                    checked={response.value2Inclusive}
                    height={20}
                    width={40}
                    handleDiameter={20}
                    onColor="#5F9EA0"
                    offColor="#5F9EA0"
                    uncheckedIcon={
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '100%',
                          fontSize: 10,
                        }}
                      >
                        <FontAwesomeIcon color="white" icon={faNotEqual} />
                      </div>
                    }
                    checkedIcon={
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '100%',
                          fontSize: 10,
                        }}
                      >
                        <FontAwesomeIcon color="white" icon={faEquals} />
                      </div>
                    }
                  />
                  {/* <SwitchButton
                  checked={response.value2Inclusive}
                  onChange={() => {
                    onchange(!response.value2Inclusive, 'value2Inclusive');
                  }}
                  uncheckedIcon={<FontAwesomeIcon icon={faNotEqual} />}
                  checkedIcon={<FontAwesomeIcon icon={faEquals} />}
                  height={20}
                  width={30}
                  handleDiameter={20}
                  // label={t('valueInclusive')}
                /> */}
                </div>
              )}
            </div>
          )}
        </div>
      )}
      <div className={styles.responseAction}>
        <div className={styles.score}>
          <div style={{ fontSize: '15px', margin: '0px 5px' }}>
            {t('score')}
          </div>
          <Label className={styles.label} style={{ width: '40%' }}>
            <AppInput
              type="number"
              readonly
              onKeyDown={(e) => {
                // Prevent arrow keys from changing the input value
                if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                  e.preventDefault();
                }
              }}
              min="0"
              pattern="[-]?\d*"
              className={styles.input}
              onChange={(e) => {
                const newValue = e.target.value;
                // Allow only empty string (for clearing input) or integers
                if (newValue === '' || /^-?\d+$/.test(newValue)) {
                  //   setValue(newValue);
                  onchange(newValue, 'score');
                }
              }}
              value={response.score}
            />
          </Label>
        </div>
        <div className={styles.actions}>
          <AppButton
            onClick={() => {
              updateQuestion([
                {
                  type: UPDATE_PROP,
                  prop: `response.${index}.hasComment`,
                  value: !response.hasComment,
                },
              ]);
            }}
            icon={
              <FontAwesomeIcon
                icon={faCommentDots}
                style={{
                  alignSelf: 'center',
                  color: response.hasComment ? 'white' : 'grey',
                  width: '10px',
                  height: '10px',
                }}
              />
            }
            style={{
              padding: '3px',
              borderRadius: '10px',
              margin: '0px 5px',
              background: response.hasComment
                ? 'var(--primary)'
                : 'var(--lightGrey)',
            }}
          />
          <AppButton
            onClick={() => {
              updateQuestion([
                {
                  type: UPDATE_PROP,
                  prop: `response.${index}.hasCamera`,
                  value: !response.hasCamera,
                },
              ]);
            }}
            icon={
              <FontAwesomeIcon
                icon={faCamera}
                style={{
                  alignSelf: 'center',
                  color: response.hasCamera ? 'white' : 'grey',
                  width: '10px',
                  height: '10px',
                }}
              />
            }
            style={{
              background: response.hasCamera
                ? 'var(--primary)'
                : 'var(--lightGrey)',
              padding: '3px',
              borderRadius: '10px',
              margin: '0px 5px',
            }}
          />
          {/* !!!!!!!!!!  Temporary  !!!!!!!!! */}
          {/* <AppButton
            onClick={() => {
              console.log("comment");
            }}
            icon={
              <FontAwesomeIcon
                icon={faTimes}
                style={{
                  alignSelf: "center",
                  color: "grey",
                  width: "10px",
                  height: "10px",
                }}
              />
            }
            style={{
              background: "var(--lightGrey)",
              padding: "3px",
              borderRadius: "10px",
              margin: "0px 5px",
            }}
          /> */}
          <AppButton
            onClick={() => {
              updateQuestion([
                {
                  type: DELETE_PROP,
                  prop: `response.${index}`,
                },
              ]);
            }}
            icon={
              <FontAwesomeIcon
                icon={faTrashAlt}
                style={{
                  alignSelf: 'center',
                  color: 'red',
                  width: '15px',
                  height: '15px',
                }}
              />
            }
            style={{
              padding: '3px',
              borderRadius: '10px',
              margin: '0px 5px',
            }}
          />
        </div>
      </div>
      <TranslationModal
        ref={translationModalRef}
        onSave={(newObj) => {
          let trans = {
            [newObj.column]: {
              en: newObj.translations.en,
              ar: newObj.translations.ar,
            },
          };

          updateQuestion([
            {
              type: UPDATE_PROP,
              prop: `response.${index}.translations`,
              value: trans,
            },
            {
              type: UPDATE_PROP,
              prop: `response.${index}.${newObj.column}`,
              value: newObj.translations.en,
            },
          ]);

          translationModalRef?.current?.toggleModal();
        }}
        translationObj={currentField}
      />
    </div>
  );
};

export default ResponseOption;
