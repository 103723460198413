import React from "react";
import styles from "./AppButton.module.css";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AppButton = React.forwardRef(
  (
    { value, onClick, isLoading = false, icon, disabled = false, ...props },
    ref
  ) => {
    const { className, ...rest } = props;
    const allstyles = `${styles.defaultButton} ${
      className != undefined ? styles[className] : ""
    }`;
    return (
      <button
        onClick={onClick}
        className={allstyles}
        {...rest}
        ref={ref}
        disabled={disabled || isLoading} 
      >
        {icon && icon}
        {value && value}
        {isLoading && (
          <FontAwesomeIcon
            icon={faCircleNotch}
            // className={Styles.filterIcon}
            style={{ margin: "0px 5px" }}
            spin
          />
        )}
      </button>
    );
  }
);

export default AppButton;
