import {
  faCircleNotch,
  faInfoCircle,
  faPlus,
  faSave,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef } from 'react';
import AppButton from '../../../../../../../../components/general/app-button/AppButton';
import styles from './Options.module.css';
import { filterSelectStyles } from '../../Constants';
import AppInput from '../../../../../../../../components/general/app-input/AppInput';
import Label from '../../../../../../../../components/general/label/Label';
import Option from '../../components/option/Option';
import CheckboxInput from '../../../../../../../../components/general/checkbox-input/CheckboxInput';
import Card from '../../../../../../../../components/general/card/Card';
import AppSelect from '../../../../../../../../components/general/app-select/AppSelect';
import SwitchButton from '../../../../../../../../components/general/switch-button/SwitchButton';
import ResponseOption from '../response-option/ResponseOption';
import {
  DELETE_PROP,
  UPDATE_PROP,
} from '../../../../../../../../store/ActionTypes';
import { IMAGES_BASE_URL } from '../../../../../../../../config';
import MultiEmail from '../../../../../../../../components/general/multi-email/MultiEmail';
import CreatableSelect from 'react-select/creatable';
import { ERROR } from '../../../../../../../../components/general/app-toast/AppToast';
import {
  storeTag,
  updateAuditTemplate,
} from '../../../../../../../../api/services/AuditServices';
import UseAppToast from '../../../../../../../../helpers/hooks/use-app-toast';
import { useDispatch } from 'react-redux';

import { useState } from 'react';
import { sampleResponses } from './constants';
import { useTranslation } from 'react-i18next';
import { extractErrorMessage } from '../../../../../../../../helpers/Common';
import TranslationModal from '../../../../../../../../components/general/translation-modal/TranslationModal';
import AppTooltip from '../../../../../../../../components/general/app-tooltip/AppTooltip';
const Options = ({
  question,
  updateQuestion,
  masterData,
  handleCheck,
  allUsers,
  selectedQuestion,
  setQuestionsImages,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    type,
    questionHelp,
    response,
    mandatory,
    enabled,
    showImage,
    printSize,
    selectEmailAddress,
    notificationEmailRequired,
    actionNotificationEmailRequired,
    actionMandatory,
    actionPlanAssignee,
    selectedTriggerPlan,
    selectedTriggerNotification,
    tags,
    image,
    name,
    translations
  } = question || {};
  // debugger;
  const {
    questionTypes,
    dataShowImage,
    dataPrintSize,
    dataSelectEmailAddress,
    triggerNotification,
    triggerPlan,
    dataTags,
    operators,
  } = masterData;

  // console.log("masterData in options");
  // console.log(response);
  const hiddenFileInput = useRef(null);
  const { addToast } = UseAppToast();
  const translationModalRef = useRef();
  const [currentField, setCurrentField] = useState({});
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const [loading, setloading] = useState(false);
  const generateSrc = (file) => {
    if (!file) return '';
    if (typeof file === 'string') return IMAGES_BASE_URL + file;
    console.log('url : ', URL.createObjectURL(file));
    return URL.createObjectURL(file);
  };
  console.log(tags);
  const saveTags = (selectedTags) => {
    const newTags = selectedTags.filter((item) => item.__isNew__ == true);
    console.log(newTags);
    if (newTags.length > 0) {
      storeTag(
        (res) => {
          addToast({
            type: 'success',
            description: 'saved tag Successfully',
            title: 'Success',
          });

          updateQuestion([
            {
              type: UPDATE_PROP,
              prop: `tags`,
              value: (v) => {
                console.log('tagsv');
                console.log(v);
                return v.map((item) => {
                  const { __isNew__, ...rest } = item;
                  return rest;
                });
              },
            },
          ]);

          dispatch({
            type: UPDATE_PROP,
            prop: 'survey.masterData.dataTags',
            value: (v) => {
              return [...v, res.data.response];
            },
          });
        },
        (error) => {
          addToast({
            type: ERROR,
            description: extractErrorMessage(error),
            title: 'Error',
          });
        },

        (f) => {},
        { tag: newTags[0].value },
      );
    }
  };

  return (
    <>
    {/* {JSON.stringify(question)} */}
      {question && (
        <Card className={styles.options}>
          <div className={styles.option}>
            <h5>{translations?.name?.[t('langCode')] ||name}</h5>
          </div>
          {/* Question Type */}
          <div className={styles.option}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              {/* <p className={styles.optionText}>{t('questionType')}</p> */}
              <AppTooltip
                placement="bottom"
                trigger="hover"
                buttonValue={
                  <p
                    className={styles.optionText}
                    style={{
                      cursor: 'pointer',
                      color: 'black',
                     
                    }}
                  >
                    {t('questionType')}
                  </p>
                }
                buttonStyle={{ all: 'unset' }}
                renderProp={() => (
                  <div
                    style={{
                      backgroundColor: 'white',
                      padding: '10px',
                      borderRadius: '8px',
                      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
                      maxWidth: '250px',
                      textAlign: 'left',
                      border: '1px solid #ddd',
                    }}
                  >
                    <p style={{ margin: 0, fontSize: '13px', color: '#555' }}>
                     {t('questionTypeDefinition')}
                    </p>
                  </div>
                )}
              />
            </div>
            <AppSelect
              options={questionTypes.map((element) => ({
                value: element,
                label: t(element),
              }))}
              styles={filterSelectStyles}
              value={{
                value: type,
                label: t(type),
              }}
              onChange={(e) => {
                console.log(e);
                updateQuestion([
                  { type: UPDATE_PROP, prop: 'type', value: e.value },
                ]);
              }}
            />
          </div>
          {/* Mandatory & Enabled */}
          <div className={styles.option}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              <SwitchButton
                checked={mandatory}
                onChange={() => {
                  updateQuestion([
                    {
                      type: UPDATE_PROP,
                      prop: `mandatory`,
                      value: !mandatory,
                    },
                  ]);
                }}
                label={
                  <AppTooltip
                    placement="bottom"
                    trigger="hover"
                    buttonValue={
                      <span
                        style={{
                          cursor: 'pointer',
                          color: 'black',
                          fontWeight:"bold"
                        }}
                      >
                        {t('mandatory')}
                      </span>
                    }
                    buttonStyle={{ all: 'unset' }}
                    renderProp={() => (
                      <div
                        style={{
                          backgroundColor: 'white',
                          padding: '10px',
                          borderRadius: '8px',
                          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
                          maxWidth: '250px',
                          textAlign: 'left',
                          border: '1px solid #ddd',
                        }}
                      >
                        <p
                          style={{ margin: 0, fontSize: '13px', color: '#555' }}
                        >
                         {t('mandatoryQuestion')}
                        </p>
                      </div>
                    )}
                  />
                }
              />
            </div>

            <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              <SwitchButton
                checked={enabled}
                onChange={() => {
                  updateQuestion([
                    {
                      type: UPDATE_PROP,
                      prop: `enabled`,
                      value: !enabled,
                    },
                  ]);
                }}
                label={
                  <AppTooltip
                    placement="bottom"
                    trigger="hover"
                    buttonValue={
                      <span
                        style={{
                          cursor: 'pointer',
                          color: 'black',
                           fontWeight:"bold"
                        }}
                      >
                        {t('enabled')}
                      </span>
                    }
                    buttonStyle={{ all: 'unset' }}
                    renderProp={() => (
                      <div
                        style={{
                          backgroundColor: 'white',
                          padding: '10px',
                          borderRadius: '8px',
                          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
                          maxWidth: '250px',
                          textAlign: 'left',
                          border: '1px solid #ddd',
                        }}
                      >
                        <p
                          style={{ margin: 0, fontSize: '13px', color: '#555' }}
                        >
                         {t('enableDisableQuestion')}
                        </p>
                      </div>
                    )}
                  />
                }
              />
            </div>
          </div>

          {/* Response Options if Checkbox or Radio */}
          {(type === 'checkbox' || type === 'radio' || type === 'number') && (
            <>
              <Option
                title={
                  <AppTooltip
                    placement="bottom"
                    trigger="hover"
                    buttonValue={
                      <span
                        style={{
                          cursor: 'pointer',
                          color: 'black',
                        }}
                      >
                        {type == 'number' ? t('conditions') : t('response')}
                      </span>
                    }
                    buttonStyle={{ all: 'unset' }}
                    renderProp={() => (
                      <div
                        style={{
                          backgroundColor: 'white',
                          padding: '10px',
                          borderRadius: '8px',
                          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
                          maxWidth: '250px',
                          textAlign: 'left',
                          border: '1px solid #ddd',
                        }}
                      >
                        <p
                          style={{ margin: 0, fontSize: '13px', color: '#555' }}
                        >
                          {type == 'number'
                            ? 'This defines the conditions for this question.'
                            : 'This defines the response options available for selection.'}
                        </p>
                      </div>
                    )}
                  />
                }
              >
                {response.map((item, index) => {
                  return (
                    // <div>
                    <ResponseOption
                      response={item}
                      key={item.id}
                      index={index}
                      updateQuestion={updateQuestion}
                      type={type}
                      operators={operators}
                    />
                    // </div>
                  );
                })}
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '10px',
                    flexWrap: 'wrap',
                  }}
                >
                  <AppButton
                    className="primarySmallButton"
                    icon={
                      <FontAwesomeIcon
                        icon={faPlus}
                        style={{ marginRight: '5px' }}
                      />
                    }
                    style={{
                      marginRight: '10px',
                      fontSize: '13px',
                      marginBottom: '4px',
                    }}
                    onClick={() =>
                      updateQuestion([
                        {
                          type: UPDATE_PROP,
                          prop: `response`,
                          value: (v) => [
                            ...v,
                            {
                              id: Math.floor(Math.random() * Date.now()),
                              label: '',
                              score: 0,
                              hasCamera: false,
                              hasComment: 1,
                              color: 'black',
                              isChecked: false,
                              value2Inclusive: false,
                            },
                          ],
                        },
                      ])
                    }
                    value={
                      type == 'number' ? t('addCondition') : t('addResponse')
                    }
                  />
                  {type != 'number' && (
                    <AppButton
                      className="whiteButton"
                      icon={
                        <FontAwesomeIcon
                          icon={faPlus}
                          style={{ marginRight: '5px' }}
                        />
                      }
                      style={{
                        padding: '2px 4px',
                        fontSize: '13px',
                        lineHeight: 1,
                        marginBottom: '4px',
                        borderRadius: '8px',
                      }}
                      onClick={() =>
                        /**
                         * SAMPLE RESPONSES IS NEEDED HARDCODED DATA
                         * DO NOT DELETE UNLESS YOU'RE MODIFYING THE LOGIC/DATA
                         */
                        updateQuestion([
                          {
                            type: UPDATE_PROP,
                            prop: `response`,
                            value: (v) => {
                              return [...v, ...sampleResponses];
                            },
                          },
                        ])
                      }
                      value={t('yesNo')}
                    />
                  )}
                </div>
              </Option>
              {type != 'number' && (
                <Option
                  title={
                    <AppTooltip
                      placement="bottom"
                      trigger="hover"
                      buttonValue={
                        <span
                          style={{
                            cursor: 'pointer',
                            color: 'black',
                          }}
                        >
                          {t('defaultChoice')}
                        </span>
                      }
                      buttonStyle={{ all: 'unset' }}
                      renderProp={() => (
                        <div
                          style={{
                            backgroundColor: 'white',
                            padding: '10px',
                            borderRadius: '8px',
                            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
                            maxWidth: '250px',
                            textAlign: 'left',
                            border: '1px solid #ddd',
                          }}
                        >
                          <p
                            style={{
                              margin: 0,
                              fontSize: '13px',
                              color: '#555',
                            }}
                          >
                           {t('defaultSelectedOption')}
                          </p>
                        </div>
                      )}
                    />
                  }
                >
                  <Label
                    name={t('selectDefault')}
                    className={styles.label}
                    style={{ marginTop: '10px' }}
                  >
                    <AppSelect
                      options={[
                        {
                          value: 'select_default',
                          label: t('selectDefault'),
                        },
                        ...response.map((element) => ({
                          value: element.label,
                          label: element.label,
                        })),
                      ]}
                      styles={filterSelectStyles}
                      value={
                        response.filter((item) => item.isChecked == true)[0]
                          ? {
                              value: response.filter(
                                (item) => item.isChecked == true,
                              )[0]?.label,
                              label: response.filter(
                                (item) => item.isChecked == true,
                              )[0]?.label,
                            }
                          : {
                              value: 'select_default',
                              label: t('selectDefault'),
                            }
                      }
                      onChange={(e) => {
                        response.map((element, index) => {
                          if (element.value == 'select_default') {
                            updateQuestion([
                              {
                                type: UPDATE_PROP,
                                prop: `response.${index}.isChecked`,
                                value: false,
                              },
                            ]);
                          }
                          if (element.label == e.value) {
                            console.log('true');
                            updateQuestion([
                              {
                                type: UPDATE_PROP,
                                prop: `response.${index}.isChecked`,
                                value: true,
                              },
                            ]);
                          } else {
                            console.log('false');
                            updateQuestion([
                              {
                                type: UPDATE_PROP,
                                prop: `response.${index}.isChecked`,
                                value: false,
                              },
                            ]);
                          }
                        });
                      }}
                    />
                  </Label>
                </Option>
              )}
            </>
          )}
          {/* Question Help */}
          <Option
            title={
              <AppTooltip
                placement="bottom"
                trigger="hover"
                buttonValue={
                  <span
                    style={{
                      cursor: 'pointer',
                      color: 'black',
                    }}
                  >
                    {t('questionHelp')}
                  </span>
                }
                buttonStyle={{ all: 'unset' }}
                renderProp={() => (
                  <div
                    style={{
                      backgroundColor: 'white',
                      padding: '10px',
                      borderRadius: '8px',
                      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
                      maxWidth: '250px',
                      textAlign: 'left',
                      border: '1px solid #ddd',
                    }}
                  >
                    <p style={{ margin: 0, fontSize: '13px', color: '#555' }}>
                    {t('additionalGuidance')}
                    </p>
                  </div>
                )}
              />
            }
          >
            <Label className={styles.label}>
              <AppInput
                className={styles.input}
                value={
                  question.translations?.questionHelp?.[t('langCode')] ||
                  question.questionHelp
                }
                onChange={(e) => {
                  let updatedValue = e.target.value;
                  let updatedTranslations = {
                    ...question.translations,
                    questionHelp: {
                      ...question.translations?.questionHelp,
                      [t('langCode')]: updatedValue,
                    },
                  };
                  updateQuestion([
                    {
                      type: UPDATE_PROP,
                      prop: `questionHelp`,
                      value: updatedValue,
                    },
                    {
                      type: UPDATE_PROP,
                      prop: `translations`,
                      value: updatedTranslations,
                    },
                  ]);
                }}
                inputStyle={{ minHeight: '30px' }}
                textarea
                onTranslation={() => {
                  setCurrentField({
                    column: 'questionHelp',
                    translations: question.translations['questionHelp'],
                  });
                  translationModalRef?.current?.toggleModal();
                }}
              />
            </Label>
          </Option>
          {/* Question Help Image */}
          <Option
            title={
              <AppTooltip
                placement="bottom"
                trigger="hover"
                buttonValue={
                  <span
                    style={{
                      cursor: 'pointer',
                      color: 'black',
                    }}
                  >
                    {t('addEditPicture')}
                  </span>
                }
                buttonStyle={{ all: 'unset' }}
                renderProp={() => (
                  <div
                    style={{
                      backgroundColor: 'white',
                      padding: '10px',
                      borderRadius: '8px',
                      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
                      maxWidth: '250px',
                      textAlign: 'left',
                      border: '1px solid #ddd',
                    }}
                  >
                    <p style={{ margin: 0, fontSize: '13px', color: '#555' }}>
                    {t('addEditPicture2')}
                    </p>
                  </div>
                )}
              />
            }
          >
            <AppInput
              ref={hiddenFileInput}
              type="file"
              onChange={(e) => {
                const myNewFile = new File(
                  [e.target.files[0]],
                  `${selectedQuestion.sectionindex}-${selectedQuestion.subsectionindex}-${selectedQuestion.questionIndex}-${e.target.files[0].name}`,
                  { type: e.target.files[0].type },
                );
                updateQuestion([
                  {
                    type: UPDATE_PROP,
                    prop: `image`,
                    value: myNewFile,
                  },
                ]);
                setQuestionsImages(
                  myNewFile,
                  `${selectedQuestion.sectionindex}-${selectedQuestion.subsectionindex}-${selectedQuestion.questionIndex}`,
                );
              }}
              inputStyle={{ minHeight: '30px', display: 'none' }}
            />
            <AppButton
              classes="buttonIcon"
              style={{
                color: 'white',
                background: '#b8b8b8',
                padding: '13px 10px',
                fontSize: '13px',
                borderRadius: '6px',
                marginBottom: '20px',
                flexDirection: 'column',
              }}
              value={t('upload')}
              onClick={handleClick}
              icon={<FontAwesomeIcon icon={faPlus} />}
            />
            {/* {images.map((element, index) => ( */}
            {image && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                }}
              >
                <img
                  src={generateSrc(image)}
                  width={250}
                  // on click, open image in modal
                  onClick={() => {
                    window.open(generateSrc(image), '_blank');
                  }}
                />

                <FontAwesomeIcon
                  icon={faTimes}
                  color="grey"
                  style={{
                    fontSize: '15px',
                    marginLeft: '5px',
                  }}
                  onClick={() => {
                    updateQuestion([
                      {
                        type: UPDATE_PROP,
                        prop: `image`,
                        value: null,
                      },
                    ]);
                  }}
                />
              </div>
            )}
            <Label
              name={t('showImage')}
              className={styles.label}
              style={{ marginTop: '20px' }}
            >
              <AppSelect
                options={dataShowImage.map((element) => ({
                  value: element,
                  label: element,
                }))}
                styles={filterSelectStyles}
                value={{ value: showImage, label: showImage }}
                onChange={(e) => {
                  console.log(e);
                  updateQuestion([
                    {
                      type: UPDATE_PROP,
                      prop: `showImage`,
                      value: e.value,
                    },
                  ]);
                }}
              />
            </Label>
            <Label
              name={t('printSize')}
              className={styles.label}
              style={{ marginTop: '20px' }}
            >
              <AppSelect
                options={dataPrintSize.map((element) => ({
                  value: element,
                  label: element,
                }))}
                styles={filterSelectStyles}
                value={{ value: printSize, label: printSize }}
                onChange={(e) => {
                  console.log(e);
                  updateQuestion([
                    {
                      type: UPDATE_PROP,
                      prop: `printSize`,
                      value: e.value,
                    },
                  ]);
                }}
              />
            </Label>
          </Option>
          {/* Question Notification and Actionplan */}
          {!(type === 'text') && (
            <>
              <Option
                title={
                  <AppTooltip
                    placement="bottom"
                    trigger="hover"
                    buttonValue={
                      <span
                        style={{
                          cursor: 'pointer',
                          color: 'black',
                        }}
                      >
                        {t('notification')}
                      </span>
                    }
                    buttonStyle={{ all: 'unset' }}
                    renderProp={() => (
                      <div
                        style={{
                          backgroundColor: 'white',
                          padding: '10px',
                          borderRadius: '8px',
                          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
                          maxWidth: '250px',
                          textAlign: 'left',
                          border: '1px solid #ddd',
                        }}
                      >
                        <p
                          style={{ margin: 0, fontSize: '13px', color: '#555' }}
                        >
                          {t('configureNotifications')}
                        </p>
                      </div>
                    )}
                  />
                }
              >
                <SwitchButton
                  checked={notificationEmailRequired}
                  onChange={() => {
                    updateQuestion([
                      {
                        type: UPDATE_PROP,
                        prop: `notificationEmailRequired`,
                        value: !notificationEmailRequired,
                      },
                    ]);
                  }}
                  label={t('notificationEmailRequired')}
                />
                {notificationEmailRequired && (
                  <>
                    <Label
                      name={t('emails')}
                      className={styles.label}
                      style={{ marginTop: '20px', padding: '2px' }}
                    >
                      <MultiEmail
                        emails={selectEmailAddress}
                        setEmails={(value) => {
                          updateQuestion([
                            {
                              type: UPDATE_PROP,
                              prop: `selectEmailAddress`,
                              value: value,
                            },
                          ]);
                        }}
                      />
                      {/* <AppSelect
                                    options={dataSelectEmailAddress.map((element) => ({
                                    value: element,
                                    label: element,
                                    }))}
                                    styles={filterSelectStyles}
                                    value={selectEmailAddress}
                                    onChange={(e) => {
                                    console.log(e);

                                    updateQuestion([
                                        {
                                        type: UPDATE_PROP,
                                        prop: `selectEmailAddress`,
                                        value: e,
                                        },
                                    ]);
                                    }}
                                    isMulti={true}
                                /> */}
                    </Label>
                    {response.length > 0 && (
                      <>
                        <div className={styles.text}>
                          {t('whichResponsesTriggerNotification')}
                        </div>
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                          {response.map(
                            (element, index) =>
                              element.label && (
                                <CheckboxInput
                                  key={index}
                                  name={element.label + 'notification'}
                                  label={
                                    element.translations?.label?.[
                                      t('langCode')
                                    ] || element.label
                                  }
                                  isChecked={selectedTriggerNotification.includes(
                                    `${element.id}`,
                                  )}
                                  onChange={(e) => {
                                    handleCheck(
                                      e,
                                      `selectedTriggerNotification`,
                                      selectedTriggerNotification,
                                    );
                                  }}
                                  value={element.id}
                                  className={styles.singleInput}
                                />
                              ),
                          )}
                        </div>
                      </>
                    )}
                  </>
                )}
              </Option>
              <Option
                title={
                  <AppTooltip
                    placement="bottom"
                    trigger="hover"
                    buttonValue={
                      <span
                        style={{
                          cursor: 'pointer',
                          color: 'black',
                        }}
                      >
                        {t('actionPlan')}
                      </span>
                    }
                    buttonStyle={{ all: 'unset' }}
                    renderProp={() => (
                      <div
                        style={{
                          backgroundColor: 'white',
                          padding: '10px',
                          borderRadius: '8px',
                          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
                          maxWidth: '250px',
                          textAlign: 'left',
                          border: '1px solid #ddd',
                        }}
                      >
                        <p
                          style={{ margin: 0, fontSize: '13px', color: '#555' }}
                        >
                         {t('defineSteps')}
                        </p>
                      </div>
                    )}
                  />
                }
              >
                {/* *** NOTE *** Removing this as email sending is required by default */}
                {/* <SwitchButton
                                    checked={actionNotificationEmailRequired}
                                    onChange={() => {
                                        updateQuestion([
                                            {
                                                type: UPDATE_PROP,
                                                prop: `actionNotificationEmailRequired`,
                                                value: !actionNotificationEmailRequired,
                                            },
                                        ]);
                                    }}
                                    label="Notification Email Required"
                                /> */}
                <SwitchButton
                  checked={actionMandatory}
                  onChange={() => {
                    updateQuestion([
                      {
                        type: UPDATE_PROP,
                        prop: `actionMandatory`,
                        value: !actionMandatory,
                      },
                    ]);
                  }}
                  label={t('actionPlanRequired')}
                />
                {response.length > 0 && actionMandatory && (
                  <>
                    <div className={styles.text}>
                      {t('whichResponsesTriggerPlan')}
                    </div>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                      {response.map((element, index) => (
                        <CheckboxInput
                          key={index}
                          name={element.label + 'plan'}
                          label={element.label}
                          isChecked={selectedTriggerPlan.includes(
                            `${element.id}`,
                          )}
                          onChange={(e) => {
                            // console.warn('qqqqq')
                            console.error(selectedTriggerPlan, 'plan');
                            handleCheck(
                              e,
                              'selectedTriggerPlan',
                              selectedTriggerPlan,
                            );
                          }}
                          value={element.id}
                          className={styles.singleInput}
                        />
                      ))}
                    </div>
                    <Label
                      name={t('assignee')}
                      className={styles.label}
                      style={{ marginTop: '20px' }}
                    >
                      <AppSelect
                        options={allUsers?.data?.map((element) => ({
                          value: element.id,
                          label: element.name,
                        }))}
                        styles={filterSelectStyles}
                        value={actionPlanAssignee ?? ''}
                        onChange={(e) => {
                          console.log(e);
                          debugger;
                          updateQuestion([
                            {
                              type: UPDATE_PROP,
                              prop: `actionPlanAssignee`,
                              value: e,
                            },
                          ]);
                        }}
                      />
                    </Label>
                  </>
                )}
              </Option>
            </>
          )}
          {/* Question Tags */}
          {false && (
            <Option title={t('tags')}>
              <Label className={styles.label}>
                <CreatableSelect
                  options={dataTags.map((element) => ({
                    value: element.tag,
                    label: element.tag,
                    id: element.id,
                  }))}
                  isMulti
                  isSearchable={true}
                  styles={filterSelectStyles}
                  value={tags}
                  // onInputChange={(v) => {
                  //     console.log("onchangeinputvalue");
                  //     console.log(v);
                  // }}
                  onChange={(e) => {
                    console.log(e);
                    updateQuestion([
                      {
                        type: UPDATE_PROP,
                        prop: `tags`,
                        value: e,
                      },
                    ]);
                    saveTags(e);
                  }}
                />
              </Label>
            </Option>
          )}
        </Card>
      )}
      <TranslationModal
        ref={translationModalRef}
        onSave={(newObj) => {
          if (
            newObj.translations &&
            newObj.translations.en &&
            newObj.translations.ar
          ) {
            const trans = {
              ...question.translations,
              [newObj.column]: newObj.translations,
            };

            updateQuestion([
              {
                type: UPDATE_PROP,
                prop: `translations`,
                value: trans,
              },
              {
                type: UPDATE_PROP,
                prop: newObj.column,
                value: newObj.translations.en,
              },
            ]);

            translationModalRef?.current?.toggleModal();
          } else {
            console.error('Translations not found');
          }
        }}
        translationObj={currentField}
      />
    </>
  );
};

export default Options;
