import { useLocation, useNavigate } from 'react-router-dom';
import {
  deleteUser,
  fetchUsers,
  login,
  loginCompanies,
} from '../../api/services/UserServices';
import { encrypted, extractErrorMessage } from '../../helpers/Common';
import UseAppToast from '../../helpers/hooks/use-app-toast';
import { UPDATE_PROP } from '../../store/ActionTypes';
import {
  fecthPlans,
  submitRenewSubscription,
} from '../../api/services/SettingsServices';
import { useEffect, useMemo, useState } from 'react';
import { ERROR, SUCCESS } from '../../components/general/app-toast/AppToast';
import UseAppTable from '../../helpers/hooks/use-app-table';
import { UserColumn } from './userColumn';

export const INITIAL_STATE = {
  loading: {
    loadingPlans: false,
  },
  selectedPeriod: 0,
  users: 0,
  response: [],
  loading: false,
  showConfirmModal: false,
  isConfirmed: false,
  loadingConfirm: false,
  showUserSelectionModal: false,
  deleteLoading: false,
};
const Logic = (dispatch, state) => {
  const navigate = useNavigate();
  const { addToast } = UseAppToast();
  const location = useLocation();
  const previousUsers = location.state?.usersNumber;
  const usedUsers = location.state?.usedUsersCount;
  const { data, setData, error } = UseAppTable({
    fetchTableData: fetchUsers,
  });
  const bulkDelete = (selectedRow) => {
    dispatch({
      payload: [
        {
          type: UPDATE_PROP,
          prop: `showDeleteAlert`,
          value: false,
        },
        {
          type: UPDATE_PROP,
          prop: `deletedID`,
          value: null,
        },
      ],
    });

    setData((prevState) => {
      return {
        ...prevState,
        selectedRows: selectedRow,
      };
    });
  };
  const toggleLoading = (prop, value) => {
    dispatch({
      payload: [{ type: UPDATE_PROP, prop, value }],
    });
  };
  const getPlans = () => {
    toggleLoading('loading.loadingPlans', true);
    fecthPlans(
      (response) => {
        dispatch({
          payload: [
            { type: UPDATE_PROP, prop: 'response', value: response.data },
          ],
        });
      },
      (error) => {
        addToast({
          type: ERROR,
          description: extractErrorMessage(error),
          title: 'Error',
        });
      },
      () => {
        toggleLoading('loading.loadingPlans', false);
      },
      {},
    );
  };
  const handleSubmit = () => {
    toggleLoading('loadingConfirm', true);
    const selectedUsers = state.users;
    // if (selectedUsers < previousUsers ) {
    //   toggleLoading('showConfirmModal', true);
    //   toggleLoading('loadingConfirm', false);
    //   return;
    // }
    toggleLoading('showConfirmModal', false);
    toggleLoading('loading.loadingSubmit', true);

    let param = {
      plan_id:
        state.response[state.selectedPeriod].plans[state.selectedPlan].plan_id,
      plan_pricing_id:
        state.response[state.selectedPeriod].plans[state.selectedPlan]
          .plan_pricing_id,
      number_of_users: state.users,
    };
    submitRenewSubscription(
      (response) => {
        navigate('/success', {
          state: {
            message:
              'Thank you for your subscription request! We’ve received it and will be in touch shortly.',
            nextNavigation: '/',
          },
        });
      },
      (error) => {
        addToast({
          type: ERROR,
          description: extractErrorMessage(error),
          title: 'Error',
        });
      },
      () => {
        toggleLoading('loading.loadingSubmit', false);
        toggleLoading('loadingConfirm', false);
      },
      param,
    );
  };
  const HandelDelete = () => {
    const selectedArray = data.selectedRows.map((d) => d.id);
    console.log('selectedArray', selectedArray);
    dispatch({
      payload: [{ type: UPDATE_PROP, prop: 'deleteLoading', value: true }],
    });
    deleteUser(
      (res) => {
        let arrResults = [...data.results];
        selectedArray.map((d) => {
          const index = arrResults.findIndex((x) => {
            console.log(x.id);
            return x.id === d;
          });
          arrResults.splice(index, 1);
        });
        setData((prevState) => {
          let newData = {
            results: [...arrResults],
            total: prevState.total - 1,
          };
          if (arrResults.length == 0) {
            newData = {
              ...newData,
              params: {
                ...prevState.params,
                page: prevState.params.page - 1,
              },
            };
          }
          return {
            ...prevState,
            ...newData,
          };
        });
        handleCloseDelete();

        addToast({
          type: SUCCESS,
          description: 'Deleted successfully',
          title: 'Success',
        });
      },
      (error) => {
        handleCloseDelete();

        addToast({
          type: ERROR,
          description: 'Something went wrong',
          title: 'Error',
        });
      },
      () => {
        dispatch({
          payload: [{ type: UPDATE_PROP, prop: 'deleteLoading', value: false }],
        });
      },
      { ids: selectedArray },
    );
  };
  const handleShowConfimDelete = (id) => {
    dispatch({
      payload: [
        {
          type: UPDATE_PROP,
          prop: `showDeleteAlert`,
          value: true,
        },
        {
          type: UPDATE_PROP,
          prop: `deletedID`,
          value: id,
        },
      ],
    });
  };
  const handleCloseDelete = () => {
    dispatch({
      payload: [
        {
          type: UPDATE_PROP,
          prop: `showDeleteAlert`,
          value: false,
        },
        {
          type: UPDATE_PROP,
          prop: `deletedID`,
          value: null,
        },
      ],
    });
  };
  const actions = () => {
    return {
      deactivate: handleShowConfimDelete,
      navigate,
      bulkDelete: bulkDelete,
    };
  };
  let columns = useMemo(() => UserColumn(actions()));
  useEffect(() => {
    getPlans();
  }, []);
  return {
    actions: { handleSubmit, HandelDelete, handleCloseDelete },
    data,
    error,
    columns,
    setData
  };
};

export default Logic;
