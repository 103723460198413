import Switch from 'react-switch';
import { Constants } from './Constants';
import Styles from './SwitchButton.module.css';
const SwitchButton = ({ onChange, label, className, ...rest }) => {
  return (
    <div id={Styles.switch} className={className}>
      {label && <span>{label}</span>}
      <Switch
        onChange={onChange}
        onColor={rest.onColor ? `${rest.onColor}` : `${Constants.onColor}`}
        onHandleColor={
          rest.onHandleColor
            ? `${rest.onHandleColor}`
            : `${Constants.onHandleColor}`
        }
        handleDiameter={rest.handleDiameter ?? Constants.handleDiameter}
        uncheckedIcon={false}
        checkedIcon={false}
        boxShadow={rest.boxShadow ?? Constants.boxShadow}
        activeBoxShadow={rest.activeBoxShadow ?? Constants.activeBoxShadow}
        height={rest.height ?? Constants.height}
        width={rest.width ?? Constants.width}
        className="react-switch"
        id="material-switch"
        {...rest}
      />
    </div>
  );
};

export default SwitchButton;
