import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { useTranslation } from 'react-i18next';
import ConfirmModal from '../../../components/general/confirm-modal/ConfrimModal';
import TableLayout from '../../../components/general/table-layout/TableLayout';
import useLogic from '../../../helpers/hooks/use-logic';
import Logic, { INITIAL_STATE } from './logic';
import { getUserFromLS } from '../../../helpers/Storage';

const Users = () => {
  const {
    state,
    handleDelete,
    setData,
    navigate,
    columns,
    data,
    error,
    showDeleteAlert,
    setShowDeleteAlert,
    HandleCloseDelete,
  } = useLogic({
    INITIAL_STATE,
    Logic,
  });
  const { t } = useTranslation();
  const user = getUserFromLS();
  const filterData = [
    {
      key: 'name',
      label: 'Name',
      sign: [{ value: 'contains', label: t('contains') }],
    },
    {
      key: 'email',
      label: 'Email',
      sign: [{ value: 'contains', label: t('contains') }],
    },

    // ... other filters
  ];

  const filterKeys = {
    name: {
      type: 'string',
      dropdown: false,
      sign: [
        { value: 'contains', label: t('contains') },
        { value: 'is_equal', label: t('equals') },
        // ... other signs
      ],
    },
    email: {
      type: 'string',
      dropdown: false,
      sign: [
        { value: 'contains', label: t('contains') },
        { value: 'is_equal', label: t('equals') },
        // ... other signs
      ],
    },
  };
  return (
    <>
      {/* {console.log(data)} */}
      <TableLayout
        tableColumns={columns}
        title={`${t('users')} (${data.total}/${user?.subscription?.number_of_users})`}
        showButton={true}
        data={data}
        error={error}
        buttonValue={
          data.selectedRows.length !== 0
            ? `${t('deleteSelected')} (${data.selectedRows.length})`
            : t('new')
        }
        buttonIcon={
          data.selectedRows.length !== 0 ? (
            <FontAwesomeIcon
              icon={faTrashAlt}
              style={{ marginRight: '5px', marginLeft: '5px' }}
            />
          ) : (
            <FontAwesomeIcon
              icon={faPlus}
              style={{ marginRight: '5px', marginLeft: '5px' }}
            />
          )
        }
        // buttonOnClick={() => navigate('/portal/add-user')}
        buttonOnClick={() =>
          data.selectedRows.length !== 0
              ? setShowDeleteAlert(true)
              : navigate("/portal/add-user")
      }
        buttonStyle={{ color: 'white' }}
        buttonClassName="primaryButton"
        showFilter={true}
        filterData={{ filterData }}
        filterKeys={{ filterKeys }}
        setData={setData}
        showCheckbox
      />
      {showDeleteAlert && (
        <ConfirmModal
          show={showDeleteAlert}
          onHide={() => HandleCloseDelete()}
          onSave={() => handleDelete()}
          isLoading={state.deleteLoading}
        />
      )}
    </>
  );
};
export default Users;
