import classes from './Label.module.css';

const Label = ({
  children,
  name,
  classText,
  className,
  innerStyle = {},
  ...rest
}) => {
  return (
    <div className={`${classes.label} ${className}`} {...rest}>
      {name && (
        <div
          className={`${classes.name} ${classText}`}
          style={{ ...innerStyle }}
        >
          {name}
        </div>
      )}
      {children}
    </div>
  );
};

export default Label;
