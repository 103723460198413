import {
  Autocomplete,
  Circle,
  GoogleMap,
  Marker,
  StandaloneSearchBox,
} from '@react-google-maps/api';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AppButton from '../../../../components/general/app-button/AppButton';
import AppInput from '../../../../components/general/app-input/AppInput';
import AppSelect from '../../../../components/general/app-select/AppSelect';
import Card from '../../../../components/general/card/Card';
import Label from '../../../../components/general/label/Label';
import LoadingSpinner from '../../../../components/general/loading-spinner/LoadingSpinner';
import MultiEmail from '../../../../components/general/multi-email/MultiEmail';
import Title from '../../../../components/general/title/Title';
import ValidationProvider from '../../../../components/general/validation-provider/ValidationProvider';
import useLogic from '../../../../helpers/hooks/use-logic';
import useValidator from '../../../../helpers/hooks/use-validator';
import Styles from './AddLocation.module.css';
import { filterSelectStyles } from './Constants';
import Logic, { INITIAL_STATE } from './logic';
import TranslationModal from '../../../../components/general/translation-modal/TranslationModal';
import { UPDATE_PROP } from '../../../../store/ActionTypes';

const AddLocation = () => {
  const { t } = useTranslation();
  const {
    state,
    updateState,
    handleUpdateOrCreate,
    navigate,
    id,
    duplicate,
    onLoad,
    onUnmount,
    isLoaded,
    updateLocationOnClick,
  } = useLogic({
    INITIAL_STATE,
    Logic,
  });
  const [position, setPosition] = React.useState();
  const autocompleteRef = React.useRef(null);
  const onPlaceChanged = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      if (place.geometry) {
        state.position({
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        });
      }
    }
  };

  let re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const { errors, onBlur, onChange, handleSubmit } = useValidator({
    defaultValues: {
      ...state.form,
    },
    setValue: (nextFormState) => {
      updateState({
        prop: 'form',
        value: nextFormState,
      });
    },
    onSubmit: handleUpdateOrCreate,
    validations: {
      name: {
        required: true,
        validation_name: 'Location name',
      },
      code: { required: false, validation_name: 'Location code' },
      selectedTimezone: { required: false, validation_name: 'Timezone' },
      zipcode: { required: false, validation_name: 'Zip Code' },
      longitude: { required: true, validation_name: 'Longitude' },
      latitude: { required: true, validation_name: 'Latitude' },
      radius: { required: true, validation_name: 'Radius' },
      address: { required: false, validation_name: 'Address' },
      selectedArea: { required: false, validation_name: 'Area' },
      to_emails: {
        validate: (value) => {
          return !value.every((email) => {
            return re.test(email);
          });
        },
        validation_name: 'To Emails',
      },
      cc_emails: {
        // required: true,
        validate: (value) => {
          return !value.every((email) => {
            return re.test(email);
          });
        },
        validation_name: 'Cc Emails',
      },
    },
  });
  const translationModalRef = useRef();
  const [currentField, setCurrentField] = useState({});
  console.log('State at initial render:', state);

  return (
    <div className={Styles.container}>
      <div className={Styles.title}>
        <Title
          title={`${duplicate ? t('add') : id ? t('update') : t('add')} ${t(
            'location',
          )}`}
        ></Title>
      </div>
      <Card style={{ padding: '20px 10px' }}>
        <LoadingSpinner isLoading={state.pageLoading} error={state.error}>
          {/* <form> */}
          <div className={Styles.formContainer}>
            <div className={Styles.sectionContainer}>
              <Title title={t('details')} border={true} />
              <div style={{ marginTop: '15px', padding: '0px 20px' }}>
                <div className={Styles.groupControl}>
                  <div className={Styles.inputControl}>
                    <ValidationProvider
                      error={errors.name}
                      render={({ requiredTitle }) => (
                        <Label
                          name={requiredTitle(t('name'))}
                          className={Styles.label}
                        >
                          <AppInput
                            type="text"
                            className={Styles.input}
                            onChange={(e) => {
                              const updatedValue = e.target.value;

                              const updatedTranslations = {
                                ...state.form.translations,
                                name: {
                                  ...state.form.translations['name'],
                                  [t('langCode')]: updatedValue,
                                },
                              };

                              updateState({
                                type: UPDATE_PROP,
                                prop: 'form',
                                value: {
                                  ...state.form,
                                  name: updatedValue,
                                  translations: updatedTranslations,
                                },
                              });
                            }}
                            value={
                              state.form.translations['name']?.[
                                t('langCode')
                              ] || state.form.name
                            }
                            onBlur={(e) => onBlur('name')}
                            onTranslation={() => {
                              console.log(
                                'Current translations before opening modal:',
                                state.form.translations?.['name'],
                              );

                              setCurrentField({
                                column: 'name',
                                translations: state.form?.translations?.[
                                  'name'
                                ] || { en: '', ar: '' },
                              });

                              translationModalRef?.current?.toggleModal();
                            }}
                          />
                        </Label>
                      )}
                    />
                  </div>
                  <div className={Styles.inputControl}>
                    <ValidationProvider
                      error={errors.code}
                      render={({ requiredTitle }) => (
                        <Label name={t('code')} className={Styles.label}>
                          <AppInput
                            type="text"
                            className={Styles.input}
                            value={state.form.code}
                            onChange={(e) => onChange('code', e.target.value)}
                            onBlur={(e) => onBlur('code')}
                          />
                        </Label>
                      )}
                    />
                  </div>
                </div>
                <div className={Styles.groupControl}>
                  <div className={Styles.inputControl}>
                    <ValidationProvider
                      error={errors.selectedTimezone}
                      render={({ requiredTitle }) => (
                        <Label name={t('timeZone')} className={Styles.label}>
                          <AppSelect
                            options={state.timezones.map((element) => ({
                              value: element.timezone,
                              label: element.timezone,
                            }))}
                            styles={filterSelectStyles}
                            value={state.form.selectedTimezone}
                            onChange={(e) => onChange('selectedTimezone', e)}
                            onBlur={(e) => onBlur('selectedTimezone')}
                          />
                        </Label>
                      )}
                    />
                  </div>
                  <div className={Styles.inputControl}>
                    <ValidationProvider
                      error={errors.zipcode}
                      render={({ requiredTitle }) => (
                        <Label name={t('zipCode')} className={Styles.label}>
                          <AppInput
                            type="text"
                            className={Styles.input}
                            value={state.form.zipcode}
                            onChange={(e) =>
                              onChange('zipcode', e.target.value)
                            }
                            onBlur={(e) => onBlur('zipcode')}
                          />
                        </Label>
                      )}
                    />
                  </div>
                </div>
                <div className={Styles.groupControl}>
                  <div className={Styles.inputControl}>
                    <ValidationProvider
                      error={errors.longitude}
                      render={({ requiredTitle }) => (
                        <Label
                          name={requiredTitle(t('longitude'))}
                          className={Styles.label}
                        >
                          <AppInput
                            type="text"
                            className={Styles.input}
                            value={state.form.longitude}
                            onChange={(e) =>
                              onChange('longitude', e.target.value)
                            }
                            onBlur={(e) => onBlur('longitude')}
                          />
                        </Label>
                      )}
                    />
                  </div>
                  <div className={Styles.inputControl}>
                    <ValidationProvider
                      error={errors.latitude}
                      render={({ requiredTitle }) => (
                        <Label
                          name={requiredTitle(t('latitude'))}
                          className={Styles.label}
                        >
                          <AppInput
                            type="text"
                            className={Styles.input}
                            value={state.form.latitude}
                            onChange={(e) =>
                              onChange('latitude', e.target.value)
                            }
                            onBlur={(e) => onBlur('latitude')}
                          />
                        </Label>
                      )}
                    />
                  </div>
                </div>

                <div className={Styles.groupControl}>
                  <div className={Styles.inputControl}>
                    <ValidationProvider
                      error={errors.radius}
                      render={({ requiredTitle }) => (
                        <Label
                          name={requiredTitle(t('radius'))}
                          className={Styles.label}
                        >
                          <AppInput
                            type="text"
                            className={Styles.input}
                            value={state.form.radius}
                            onChange={(e) => onChange('radius', e.target.value)}
                            onBlur={(e) => onBlur('radius')}
                          />
                        </Label>
                      )}
                    />
                  </div>
                  <div className={Styles.inputControl}>
                    <ValidationProvider
                      error={errors.selectedArea}
                      render={({ requiredTitle }) => (
                        <Label name={t('area')} className={Styles.label}>
                          <AppSelect
                            options={state.area.data.map((element) => ({
                              value: element.id,
                              label: element.name,
                            }))}
                            styles={filterSelectStyles}
                            value={state.form.selectedArea}
                            onChange={(e) => onChange('selectedArea', e)}
                            onBlur={(e) => onBlur('selectedArea')}
                          />
                        </Label>
                      )}
                    />
                  </div>
                </div>

                {isLoaded ? (
                  <>
                    <Autocomplete
                      onLoad={(ref) => (autocompleteRef.current = ref)}
                      onPlacesChanged={onPlaceChanged}
                    >
                      <input
                        type="text"
                        placeholder="Search"
                        style={{
                          // position: 'absolute',
                          top: '10px',
                          left: '50%',
                          // transform: 'translateX(-50%)',
                          width: '240px',
                          height: '40px',
                          padding: '0 12px',
                          borderRadius: '4px',
                          boxShadow: '0 2px 6px rgba(0,0,0,0.3)',
                          fontSize: '16px',
                          outline: 'none',
                          textOverflow: 'ellipses',
                          zIndex: 1000,
                          backgroundColor: 'white',
                          border: '1px solid #ccc',
                        }}
                      />
                    </Autocomplete>

                    <GoogleMap
                      mapContainerStyle={{
                        width: '100%',
                        height: '400px',
                        borderRadius: '10px',
                      }}
                      center={{
                        lat: parseFloat(state.form.latitude),
                        lng: parseFloat(state.form.longitude),
                      }}
                      zoom={8}
                      onLoad={onLoad}
                      onUnmount={onUnmount}
                      onClick={(ev) => {
                        updateLocationOnClick(ev.latLng.lat(), ev.latLng.lng());
                      }}
                    >
                      <>
                        <Marker
                          position={{
                            lat: parseFloat(state.form.latitude),
                            lng: parseFloat(state.form.longitude),
                          }}
                          draggable={true}
                          onDragEnd={(ev) => {
                            updateLocationOnClick(
                              ev.latLng.lat(),
                              ev.latLng.lng(),
                            );
                          }}
                        >
                          <Circle
                            center={{
                              lat: parseFloat(state.form.latitude),
                              lng: parseFloat(state.form.longitude),
                            }}
                            radius={parseFloat(state.form.radius)}
                            options={{
                              strokeColor: 'transparent',
                              fillColor: 'blue',
                              strokeOpacity: 0.8,
                            }}
                          />
                        </Marker>
                        <></>
                      </>
                    </GoogleMap>
                  </>
                ) : (
                  <></>
                )}
                <div className={Styles.groupControl}>
                  <ValidationProvider
                    error={errors.address}
                    render={({ requiredTitle }) => (
                      <Label name={t('address')} className={Styles.label}>
                        <AppInput
                          value={state.form.address}
                          onChange={(e) => onChange('address', e.target.value)}
                          onBlur={(e) => onBlur('address')}
                          textarea
                        />
                      </Label>
                    )}
                  />
                </div>
                <div className={Styles.groupControl}>
                  <ValidationProvider
                    error={errors.to_emails}
                    render={({ requiredTitle }) => (
                      <Label name={t('toEmails')} className={Styles.label}>
                        <MultiEmail
                          emails={state.form.to_emails}
                          setEmails={(value) => {
                            onChange('to_emails', value);
                            console.log('to_emails', value);
                          }}
                          onBlur={(e) => onBlur('to_emails')}
                        />
                      </Label>
                    )}
                  />
                </div>
                <div className={Styles.groupControl}>
                  <ValidationProvider
                    error={errors.cc_emails}
                    render={({ requiredTitle }) => (
                      <Label name={t('ccEmails')} className={Styles.label}>
                        <MultiEmail
                          emails={state.form.cc_emails}
                          setEmails={(value) => {
                            onChange('cc_emails', value);
                            console.log('cc_emails', value);
                          }}
                          onBlur={(e) => onBlur('cc_emails')}
                        />
                      </Label>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={Styles.actionButton}>
            <AppButton
              className="primaryButton"
              type="button"
              value={t('save')}
              onClick={handleSubmit}
              isLoading={state.saveLoading}
            />
            <AppButton
              className="whiteButton"
              type="button"
              onClick={() => navigate('/portal/locations')}
              value={t('cancel')}
              style={{ margin: '0px 15px' }}
            />
          </div>
          {/* </form> */}
        </LoadingSpinner>
      </Card>
      <TranslationModal
        ref={translationModalRef}
        onSave={(newObj) => {
          console.log('Saving new translation:', newObj);

          const updatedTranslations = {
            ...state.form.translations,
            [newObj.column]: {
              en: newObj.translations.en,
              ar: newObj.translations.ar,
            },
          };

          const updatedForm = {
            ...state.form,
            translations: updatedTranslations,
            [newObj.column]: newObj.translations.en,
          };

          updateState({
            prop: 'form',
            value: updatedForm,
          });

          console.log('Updated form after saving:', updatedForm);

          translationModalRef?.current?.toggleModal();
        }}
        translationObj={currentField}
      />
    </div>
  );
};
export default AddLocation;
