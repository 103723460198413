import React, { useCallback, useEffect, useRef, useState } from 'react';
import AppInput from '../../../../../../../../components/general/app-input/AppInput';
import styles from './ElementMaker.module.css';
import TranslationModal from '../../../../../../../../components/general/translation-modal/TranslationModal';
import { useTranslation } from 'react-i18next';
import { UPDATE_PROP } from '../../../../../../../../store/ActionTypes';

const areEqual = (prevProps, nextProps) => {
  return JSON.stringify(prevProps.value) === JSON.stringify(nextProps.value);
};

const ElementMaker = ({
  value,
  handleChange,
  onTranslation,
  columnName,
  translations,
}) => {
  const { t } = useTranslation();
  const langCode = t('langCode');
  const [showInput, setShowInput] = useState(false);
  const [newValue, setNewValue] = useState(value);
  const translationModalRef = useRef();
  const [currentField, setCurrentField] = useState({});
  

  const handleUpdateTask = useCallback(
    (e) => {
      let text = e.target.value.trim();
      if (text.length < 1) {
        // text = translations?.[langCode] || value;
      }
      const updatedTranslations = {
        ...translations ,
        [langCode]: text,
      };
      setNewValue(text);
      handleChange(updatedTranslations);
    },
    [handleChange, value, translations, langCode],
  );
  const handleInputKeyPress = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        handleUpdateTask(event);
      }
    },
    [handleUpdateTask],
  );
  const handleTranslation = () => {
    // debugger;
    const updatedTranslations = { ...translations};
    const updatedField = {
      column: columnName,
      translations: updatedTranslations,
    };
    setCurrentField(updatedField);
    translationModalRef?.current?.toggleModal();
  };
  useEffect(() => {
    setNewValue(translations?.[langCode] || value);
  }, [langCode, translations, value]);
  return (
    <span>
      {showInput ? (
        <AppInput
          containerStyle={{
            flexDirection: 'row-reverse',
            alignItems: 'baseline',
          }}
          containerClassName={styles.inputContainer}
          type="text"
          className={styles.input}
          value={newValue}
          onChange={(e) => {
            const updatedValue = e.target.value;
            console.log("update value",newValue)
            setNewValue(updatedValue);
            const updatedTranslations = {
              ...translations,
              [langCode]: updatedValue,
            };
            handleChange(updatedTranslations);
          }}
          onBlur={handleUpdateTask}
          onKeyPress={handleInputKeyPress}
          onTranslation={handleTranslation}
        />
      ) : (
        <span
          onClick={() => setShowInput(true)}
          style={{
            display: 'flex',
            minHeight: 20,
            minWidth: 100,
            backgroundColor: value == '' ? '#ccc' : null,
          }}
        >
          {translations?.[langCode] || value}
        </span>
      )}

      <TranslationModal
        ref={translationModalRef}
        onSave={(newObj) => {
          const updatedTranslations = {
              ...translations,
              ...newObj.translations,
          };
          console.log('Translations in modal:', updatedTranslations);
          handleChange(updatedTranslations); 
          translationModalRef?.current?.toggleModal();
      }}
      
        translationObj={currentField}
      />
    </span>
  );
};

export default React.memo(ElementMaker, areEqual);
