import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import LanguageToggler from '../../components/general/language-toggler/LanguageToggler';
import HeaderRight from '../../components/header/header-right/HeaderRight';
import { AppHeader, AppSidebar } from '../../components/index';
import { getUserFromLS } from '../../helpers/Storage';
import HelpForm from '../../pages/HelpForm/HelpForm ';
import Classes from './DefaultLayout.module.css';
const DefaultLayout = () => {
  const [menuCollapse, setMenuCollapse] = useState(false);
  const navigate = useNavigate();
  const menuIconClick = () => {
    menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
  };
  const previousUsers = Storage.getItem('previousUsers');
  console.log('display prvios', previousUsers);
  //   const currentUsers = user?.subscription?.number_of_users;
  const user = getUserFromLS();
  // useEffect(() => {
  //     if (user?.subscription?.days_left < 0) {
  //         navigate("/", {});
  //     }
  // }, []);
  return (
    <div style={{ display: 'flex', color: 'black' }}>
      <div className={Classes.headerPopup} id="headerPopup">
        <p>
          The web application is not designed for smaller devices. For better
          experience please open on a computer screen
        </p>
        <button
          id="closePopup"
          onClick={() => {
            const headerPopup = document.getElementById('headerPopup');
            if (window.innerWidth < 1024) {
              // Show the popup
              headerPopup.style.display = 'none';
            }
          }}
        >
          X
        </button>
      </div>
      <AppSidebar
        menuCollapse={menuCollapse}
        setMenuCollapse={setMenuCollapse}
        menuIconClick={menuIconClick}
      />
      <div style={{ flex: 4, borderWidth: 1 }}>
        {user?.is_admin === 1 && user?.subscription?.days_left <= 15 && (
          <div
            style={{
              // height: 50,
              backgroundColor: '#00b7eb55',
              width: '100%',
            }}
          >
            <h5
              style={{
                textAlign: 'center',
                color: 'var(--primary)',
                fontWeight: 'bold',
                margin: 0,
                padding: 12,
              }}
            >
              Your subscription will expire in {user?.subscription?.days_left}{' '}
              days
            </h5>
          </div>
        )}
        {user?.subscription?.number_of_users > Number(previousUsers) &&
          previousUsers && (
            <div
              style={{
                backgroundColor: '#00b7eb55',
                width: '100%',
              }}
            >
              <h5
                style={{
                  textAlign: 'center',
                  color: 'var(--primary)',
                  fontWeight: 'bold',
                  margin: 0,
                  padding: 12,
                }}
              >
                Warning: The number of users exceeds your previous subscription!
              </h5>
            </div>
          )}

        <AppHeader
          right={
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <LanguageToggler color={'var(--primary)'} />
              <HeaderRight />
            </div>
          }
          classes={Classes.bgHeader}
          left={
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',

                justifyContent: 'center',
              }}
            >
              <FontAwesomeIcon
                onClick={menuIconClick}
                icon={faBars}
                style={{
                  margin: '0px 15px',
                  color: 'var(--primary)',
                  fontSize: 25,
                }}
              />
              <h5
                style={{
                  margin: 0,
                  marginLeft: 30,
                  color: 'var(--primary)',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  width: 'max-content',
                }}
              >
                {user?.company_name?.toUpperCase()}
              </h5>
            </div>
          }
          center={
            <div
              style={{
                display: 'flex',
                width: '100%',
              }}
            />
          }
        />
        <div style={{ padding: 20 }}>
          <Outlet />
          {user?.feature_permissions?.TICKETS && <HelpForm />}
        </div>
      </div>
    </div>
  );
};

export default DefaultLayout;
