export const INITIAL_STATE = {
  user: null,
  toasts: [],
  survey: {
    saveLoading: false,
    id: null,
    surveyDetails: {
      show_sign: 0,
      sign_required: 0,
      show_incharge_sign: 0,
      incharge_sign_required: 0,
      image: '',
      assignees: [],
      name: '',
      short_name: '',
      sign_label: '',
      incharge_sign_label: '',
      department_id: {},
      translations: {},
    },
    sections: [
      {
        id: 1,
        name: 'Section 1',
        collapsed: false,
        subsections: [
          {
            id: 1,
            name: 'Subsection 1',
            collapsed: false,
            questions: [],
          },
        ],
      },
    ],
    selectedQuestion: null,
    images: [],
    masterData: {
      /* Considering removing number text and date since they do not have any score effect. just for reporting.  */
      questionTypes: ['radio', 'checkbox', 'number', 'text', 'date'],
      dataShowImage: ['below', 'behind'],
      dataPrintSize: ["don't print", 'print'],
      dataSelectEmailAddress: ['zeinab@gmail.com', 'xx@gmail.com'],
      // triggerNotification: ["good", "acceptable"],
      // triggerPlan: ["yes", "no"],
      dataTags: ['question1', 'question2'],
      departments: [],
      operators: [
        'equalTo',
        'notEqualTo',
        'greaterThan',
        'lessThan',
        'between',
        'notBetween',
      ],
    },
  },
};
