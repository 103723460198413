import React from "react";
import BTable from "react-bootstrap/Table";
import { useTranslation } from "react-i18next";
import {
  useFlexLayout,
  usePagination,
  useRowSelect,
  useTable,
} from "react-table";
import AppButton from "../app-button/AppButton";
import styles from "./AppTable.module.css";
import TablePagination from "./TablePagination";
// import { useEffectSkipFirst } from "../../../helpers/Common";

const manualPageSize = [];

const AppTable = ({
  columns,
  data,
  showPagination = true,
  showCheckbox = false,
  showTotal,
  tbodyStyle,
  containerStyle,
  setData,
  error,
  func, // this is used If you want to pass a function to do a specific action
}) => {
  const { t } = useTranslation();
  const {
    results,
    total,
    queryPageIndex,
    params,
    perPage,
    selectedRows,
    loading,
  } = data;
  const totalPageCount = Math.ceil(total / perPage);

  // Use the state and functions returned from useTable to build your UI
  const defaultColumn = React.useMemo(
    () => ({
      // When using the useFlexLayout:
      minWidth: 30, // minWidth is only used as a limit for resizing
      width: 150, // width is used for both the flex-basis and flex-grow
      maxWidth: 200, // maxWidth is only used as a limit for resizing
    }),
    []
  );

  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef();
      const resolvedRef = ref || defaultRef;

      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);

      return (
        <>
          <input type="checkbox" ref={resolvedRef} {...rest} />
        </>
      );
    }
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    pageCount,
    pageOptions,
    gotoPage,
    previousPage,
    canPreviousPage,
    nextPage,
    canNextPage,
    setPageSize,
    state: { pageSize, pageIndex },
    selectedFlatRows,
    // state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data: results,
      defaultColumn,
      useControlledState: (state) => {
        return React.useMemo(
          () => ({
            ...state,
            pageIndex: params.page,
          }),
          [state, params.page]
        );
      },
      initialState: {
        pageIndex: params.page,
        hiddenColumns: columns
          .filter((col) => col.show === false)
          .map((col) => col.accessor),
      }, // Pass our hoisted table state
      manualPagination: true,
      pageCount: total ? totalPageCount : null,
      // autoResetSortBy: false,
      autoResetExpanded: false,
      autoResetPage: false,
    },
    // useSortBy,
    useFlexLayout,
    // useFilters,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (showCheckbox) {
        hooks.visibleColumns.push((columns) => [
          // column for selection
          {
            id: "selection",
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            ),
            maxWidth: 50,
            minWidth: 50,
            width: 50,
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          },
          ...columns,
        ]);
      }
    }
  );
  React.useEffect(() => {
    if (showCheckbox) {
      const newSelectedRows = selectedFlatRows.map((d) => d.original);
  
      setData((prevState) => {
        if (JSON.stringify(prevState.selectedRows) === JSON.stringify(newSelectedRows)) {
          return prevState; 
        }
        return { 
          ...prevState, 
          selectedRows: Array.isArray(newSelectedRows) ? newSelectedRows : [] 
        };
      });
    }
  }, [selectedFlatRows, showCheckbox]);
  
  
  
  // React.useEffect(() => {
  //   if (showCheckbox) {
  //     setData((prevState) => {
  //       return {
  //         ...prevState,
  //         selectedRows: selectedFlatRows.map((d) => d.original),
  //       };
  //     });
  //   }
  // }, [selectedFlatRows]);
  

  // console.log(rows);
  // useEffectSkipFirst(() => {
  //   if (rows.length == 0) {
  //     console.log(rows)
  //     setData((prevState) => {
  //       return {
  //         ...prevState,
  //         params: {
  //           ...prevState.params,
  //           page: prevState.params.page - 1,
  //         },
  //       };
  //     });
  //   }
  // }, [rows]);

  // Render the UI for your table
  return (
    <>
      <div id={styles.appTable} style={containerStyle}>
        <BTable borderless striped hover size="sm" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps([
                      {
                        className: column.className,
                        style: column.style,
                      },
                    ])}
                  >
                    {column.render("Header")}
                    {/* Render the columns filter UI */}
                    {column.filterable ? column.render("Filter") : null}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} style={tbodyStyle}>
            {loading ? (
              <tr className={styles.noResultContainer}>
                <td>{t("loading")}</td>
              </tr>
            ) : error ? (
              <tr className={styles.noResultContainer}>
                <td>{error}</td>
              </tr>
            ) : (
              (rows.length > 0 &&
                rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td
                            onClick={() => {
                              cell.column.Header == "Edit"
                                ? func(cell.row.original)
                                : console.log(cell.getCellProps());
                            }}
                            {...cell.getCellProps()}
                          >
                            {/* <AppButton
          className="primaryButton"
          onClick={saveFunction}
          // disabled={disabled}
          value="Apply"
          style={{ marginRight:10,padding: "3px 35px", marginTop: 20 }}
        /> */}
                            {cell.column.Header == "Edit" ? (
                              <AppButton
                                className="primaryButton"
                                value="Edit"
                              />
                            ) : (
                              cell.render("Cell")
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })) || (
                <tr className={styles.noResultContainer}>
                  <td>{t("noResults")}</td>
                </tr>
              )
            )}
          </tbody>
        </BTable>
      </div>
      {showPagination && (
        <div className={styles.pagination}>
          <div className={styles.tabletotalCount}>
            {t("total")} : {total}
          </div>
          {rows.length > 0 && (
            <TablePagination
              page={page}
              gotoPage={(page) => {
                setData((prevState) => {
                  return {
                    ...prevState,
                    params: {
                      ...prevState.params,
                      page,
                    },
                  };
                });
              }}
              previousPage={() => {
                setData((prevState) => {
                  return {
                    ...prevState,
                    params: {
                      ...prevState.params,
                      page:
                        prevState.params.page === 0
                          ? 0
                          : prevState.params.page - 1,
                    },
                  };
                });
              }}
              nextPage={() => {
                setData((prevState) => {
                  return {
                    ...prevState,
                    params: {
                      ...prevState.params,
                      page: prevState.params.page + 1,
                    },
                  };
                });
              }}
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              pageOptions={pageOptions}
              pageSize={perPage}
              pageIndex={pageIndex}
              setPageSize={setPageSize}
              manualPageSize={manualPageSize}
              dataLength={total}
            />
          )}
        </div>
      )}
    </>
  );
};

export default AppTable;
