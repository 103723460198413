import AppButton from '../../../components/general/app-button/AppButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { daysBetween, whereIsNowFromRange } from '../../../helpers/Common';

export const ScheduleColumn = (
  actions,
  translate = (term) => {
    return term;
  },
) => {
  return [
    {
      Header: `${translate('audit')}`,
      accessor: 'template_name',
    },
    {
      Header: `${translate('location')}`,
      accessor: 'location_name',
    },
    {
      Header: `${translate('scheduleDate')}`,
      accessor: 'date',
    },
    {
      Header: `${translate('scheduleTime')}`,
      accessor: (row) => {
        if (row.start_time && row.due_time) {
          return `${moment(row.start_time, 'hh:mm').format('hh:mm A')} - ${moment(
            row.due_time,
            'hh:mm',
          ).format('hh:mm A')}`;
        }
      },
    },
    {
      Header: `${translate('auditor')}`,
      accessor: 'username',
    },
    {
      Header: `${translate('actions')}`,
      // className: "frozen",
      style: {
        textAlign: 'center',
      },
      Cell: ({ row: { values, original } }) => {
        const currentDate = new Date();
        const auditDate = new Date(original.date);
        let displayStatus = original.status ? 'Completed' : 'Pending';

        if (
          !original.status &&
          ((!original.start_time &&
            !original.due_time &&
            daysBetween(auditDate, currentDate) > 0) ||
            (original.start_time &&
              original.due_time &&
              whereIsNowFromRange(
                auditDate,
                original.start_time,
                auditDate,
                original.due_time,
              ) == 1))
        ) {
          displayStatus = 'Missed';
        }
        return (
          <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            {displayStatus == 'Pending' && (
              <AppButton
                classes="buttonIcon"
                style={{ color: '#fb3737' }}
                onClick={() => actions.rowDelete([original])}
                value={`${translate('delete')}`}
                icon={
                  <FontAwesomeIcon
                    icon={faTrashAlt}
                    style={{ margin: '0px 5px' }}
                  />
                }
              />
            )}
          </div>
        );
      },
    },
  ];
};

export const FullListColumn = (
  actions,
  pdfLoaders,
  translate = (term) => {
    return term;
  },
) => {
  return [
    {
      Header: `${translate('audit')}`,
      accessor: 'template_name',
    },
    {
      Header: `${translate('location')}`,
      accessor: 'location_name',
    },
    {
      Header: `${translate('department')}`,
      accessor: 'department_name',
    },
    {
      Header: `${translate('scheduleDate')}`,
      accessor: 'date',
    },
    {
      Header: `${translate('scheduleTime')}`,
      accessor: (row) => {
        if (row.start_time && row.due_time) {
          return `${moment(row.start_time, 'hh:mm').format('hh:mm A')} - ${moment(
            row.due_time,
            'hh:mm',
          ).format('hh:mm A')}`;
        }
      },
    },
    {
      Header: `${translate('auditor')}`,
      accessor: 'username',
    },
    {
      Header: `${translate('status')}`,
      accessor: 'status',
      Cell: ({ row: { original, values } }) => {
        const currentDate = new Date();
        const auditDate = new Date(original.date);
        let displayStatus = original.status ? 'Completed' : 'Pending';

        if (
          !original.status &&
          ((!original.start_time &&
            !original.due_time &&
            daysBetween(auditDate, currentDate) > 0) ||
            (original.start_time &&
              original.due_time &&
              whereIsNowFromRange(
                auditDate,
                original.start_time,
                auditDate,
                original.due_time,
              ) == 1))
        ) {
          displayStatus = 'Missed';
        }

        return (
          <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            {displayStatus}
          </div>
        );
      },
    },
    {
      Header: `${translate('actions')}`,
      // className: "frozen",
      style: {
        textAlign: 'center',
      },
      Cell: ({ row: { values, original } }) => {
        const currentDate = new Date();
        const auditDate = new Date(original.date);
        let displayStatus = original.status ? 'Completed' : 'Pending';

        if (
          !original.status &&
          ((!original.start_time &&
            !original.due_time &&
            daysBetween(auditDate, currentDate) > 0) ||
            (original.start_time &&
              original.due_time &&
              whereIsNowFromRange(
                auditDate,
                original.start_time,
                auditDate,
                original.due_time,
              ) == 1))
        ) {
          displayStatus = 'Missed';
        }
        return (
          <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            {displayStatus == 'Completed' && (
              <>
                <AppButton
                  className="buttonIcon"
                  style={{ color: '#663897' }}
                  disabled={pdfLoaders[original.id]}
                  onClick={() => actions.getPdf(original.id)}
                  value={'PDF'}
                  isLoading={pdfLoaders[original.id]}
                  icon={
                    <FontAwesomeIcon
                      icon={faFilePdf}
                      style={{ margin: '0px 5px' }}
                    />
                  }
                />
              </>
            )}
            {displayStatus == 'Pending' && (
              <AppButton
                classes="buttonIcon"
                style={{ color: '#fb3737' }}
                onClick={() => actions.rowDelete([original])}
                value={`${translate('delete')}`}
                icon={
                  <FontAwesomeIcon
                    icon={faTrashAlt}
                    style={{ margin: '0px 5px' }}
                  />
                }
              />
            )}
          </div>
        );
      },
    },
  ];
};
