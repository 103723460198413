import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faSort } from '@fortawesome/free-solid-svg-icons';
import AppButton from '../../../../../../../../components/general/app-button/AppButton';
import styles from './Question.module.css';
import globalLogic from '../../../../globalLogic';
import React, { useEffect, useRef, useState } from 'react';
import AppTooltip from '../../../../../../../../components/general/app-tooltip/AppTooltip';
import ElementMaker from '../element-maker/ElementMaker';
import { UPDATE_PROP } from '../../../../../../../../store/ActionTypes';
import AppInput from '../../../../../../../../components/general/app-input/AppInput';
import TranslationModal from '../../../../../../../../components/general/translation-modal/TranslationModal';
import { useTranslation } from 'react-i18next';
// import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
// import { faClone } from "@fortawesome/free-regular-svg-icons";

const Question = ({
  itemInfo,
  index,
  sectionindex,
  subsectionindex,
  sectionId,
  subSectionId,
}) => {
  const {
    state,
    deleteQuestion,
    setSelectedQuestion,
    updateQuestion,
    duplicateQuestion,
  } = globalLogic();

  console.log('======question==============================');
  console.log(itemInfo);

  const [showInput, setShowInput] = useState(false);
  const [newValue, setNewValue] = useState(itemInfo.name);
  const translationModalRef = useRef();
  const [currentField, setCurrentField] = useState({});
  const { t } = useTranslation();
  const langCode = t('langCode');

  const handleUpdateTask = (e) => {
    let text = e.target.value;
    if (e.target.value.trim().length < 1) {
      // text =itemInfo.translations?.name?.[t('langCode')] ||itemInfo.name
      // setNewValue('');
    }
    // debugger
    setShowInput(false);
    updateQuestion([
      {
        type: UPDATE_PROP,
        prop: `name`,
        value: text,
      },
      {
        type: UPDATE_PROP,
        prop: `translations`,
        value: {
          ...itemInfo.translations, 
          name: {
            ...itemInfo.translations?.name , 
            [t('langCode')]: text,
          },
        },
      },
    ]);
  };
  // console.log('itemInfo', itemInfo);
  return (
    <>
      {/* question */}
      <h1>{showInput}</h1>
      <div
        onClick={() => {
          setShowInput(true);
          setSelectedQuestion({
            ...itemInfo,
            sectionindex,
            subsectionindex,
            questionIndex: index,
            sectionId,
            subSectionId,
            // translatedText
          });
        }}
        className={styles.question}
        style={{
          backgroundColor:
            state.selectedQuestion?.id === itemInfo.id && '#ebebeb',
        }}
      >
        <div className={styles.questionText}>
          <FontAwesomeIcon
            icon={faSort}
            color={'var(--lightGrey)'}
            className="column-drag-handle-question"
          />
          <div className={styles.questionAnswer}>
            <div className={styles.title} onBlur={() => setShowInput(false)}>
              <span>
                {showInput ? (
                  <AppInput
                  containerStyle={{
                    flexDirection: 'row-reverse',
                    alignItems: 'baseline',
                  }}
                  containerClassName={styles.inputContainer}
                  type="text"
                  className={styles.input}
                  value={
                    itemInfo.translations?.name?.[t('langCode')] ||
                    itemInfo.name
                  }
                  onChange={(e) => {
                    let updatedValue = e.target.value;
                    let updatedTranslations = {
                      ...itemInfo.translations,
                      name: {
                        ...itemInfo.translations?.name,
                        [t('langCode')]: updatedValue,
                      },
                    };
                    updateQuestion([
                      {
                        type: UPDATE_PROP,
                        prop: `name`,
                        value: updatedValue,
                      },
                      {
                        type: UPDATE_PROP,
                        prop: `translations`,
                        value: updatedTranslations,
                      },
                    ]);
                  }}
                  onTranslation={() => {
                    setCurrentField({
                      column: 'name',
                      translations: itemInfo.translations['name'],
                    });

                    translationModalRef?.current?.toggleModal();
                  }}
                  onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                      handleUpdateTask(event);
                    }
                  }}
                />
                ) : (
               
                  <span
                    onClick={() => setShowInput(true)}
                    style={{
                      display: 'flex',
                      width: '100%',
                    }}
                  >
                    {itemInfo.translations?.name?.[t('langCode')] ||
                      itemInfo.name}
                    {itemInfo.mandatory && (
                      <span style={{ color: 'red', marginLeft: '5px' }}>*</span>
                    )}
                  </span>
                )}
              </span>
            </div>
            <div className={styles.response}>
              {itemInfo.type == 'checkbox' || itemInfo.type == 'radio' ? (
                itemInfo.response.map(
                  (item, index) =>
                    (item.translations?.label?.[t('langCode')] ||
                      item.label) && (
                      <div
                        style={{
                          backgroundColor: item.color,
                          borderColor: item.color,
                          borderRadius: 10,
                          borderWidth: 1,
                          margin: '7px',
                          lineHeight: 1,
                          padding: '1px 5px',
                          fontSize: '14px',
                          color: '#fff',
                        }}
                        key={index}
                      >
                        {item.translations?.label?.[t('langCode')] ||
                          item.label}
                      </div>
                    ),
                )
              ) : itemInfo.type ? (
                <div
                  style={{
                    backgroundColor: 'var(--primary)',
                    borderColor: 'var(--primary)',
                    borderRadius: 10,
                    borderWidth: 1,
                    margin: '7px',
                    lineHeight: 1,
                    padding: '1px 5px',
                    color: '#fff',
                    fontSize: '14px',
                  }}
                  key={index}
                >
                  {itemInfo.type}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <AppTooltip
          placement="bottom-end"
          buttonValue={
            <FontAwesomeIcon
              icon={faEllipsisV}
              style={{ alignSelf: 'center', color: 'grey' }}
            />
          }
          buttonStyle={{ padding: '2px 3px', fontSize: 15 }}
          renderProp={({ setShow }) => (
            // <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <div className={styles.tooltipContent}>
              <AppButton
                className="buttonIcon"
                onClick={() => {
                  duplicateQuestion(sectionindex, subsectionindex, index);
                  setShow(false);
                }}
                style={{
                  borderRadius: 0,
                  //   color: "black",
                  color: '#2e5bff',
                }}
                value="Duplicate"
              />

              <AppButton
                className="buttonIcon"
                onClick={() => {
                  deleteQuestion(sectionindex, subsectionindex, index);
                  setShow(false);
                }}
                style={{
                  borderRadius: 0,
                  //   color: "black",
                  color: '#fb3737',
                }}
                value="Delete"
              />
            </div>
          )}
        />
      </div>
      {/* end question */}

      <TranslationModal
        ref={translationModalRef}
        onSave={(newObj) => {
          const updatedTranslations = {
            ...itemInfo.translations,
            [newObj.column]: {
              // ...itemInfo.translations[newObj.column],
              ...newObj.translations,
            },
          };

          updateQuestion([
            {
              type: UPDATE_PROP,
              prop: `translations`,
              value: updatedTranslations,
            },
            {
              type: UPDATE_PROP,
              prop: newObj.column,
              value: newObj.translations.en,
            },
          ]);

          setNewValue(newObj.translations[t('langCode')]);
          translationModalRef?.current?.toggleModal();
        }}
        translationObj={currentField}
      />

    </>
  );
};

export default Question;
