import Storage from "../helpers/Storage";
import { UPDATE_PROP } from "../store/ActionTypes";

export const updateState = (actions) => {
  return (dispatch) => {
    actions.map((action) => {
      dispatch(action);
    });
  };
};

export const loginAction = (res) => {
  return (dispatch) => {
    dispatch(
      updateState([
        {
          type: UPDATE_PROP,
          prop: "user",
          value: res,
        },
      ])
    );
  };
};
export const logout = () => {
  return (dispatch) => {
    // Storage.removeItem(AUTHENTICATION_TOKEN);
    Storage.clear();

    dispatch(
      updateState([
        {
          type: UPDATE_PROP,
          prop: "user",
          value: null,
        },
      ])
    );
  };
};
export const updateToasts = (toasts) => {
  return (dispatch) => {
    dispatch(
      updateState([
        {
          type: UPDATE_PROP,
          prop: "toasts",
          value: toasts,
        },
      ])
    );
  };
};
export const updateSections = (sections) => {
  return (dispatch) => {
    dispatch(
      updateState([
        {
          type: UPDATE_PROP,
          prop: "survey.sections",
          value: sections,
        },
      ])
    );
  };
};
export const addQuestionAction = (
  sectionindex,
  subsectionindex,
  question,
  sectionId,
  subSectionId
) => {
  return (dispatch, getState) => {
    const itemInfo = {
      id: Math.floor(Math.random() * Date.now()),
      name: question,
      translations:{
        // name:{
         
        // },
        // questionHelp:{
        
        // }
      },
      type: "radio",
      questionHelp: "",
      mandatory: true,
      enabled: true,
      showImage: "",
      printSize: "",
      selectEmailAddress: [],
      notificationEmailRequired: true,
      actionNotificationEmailRequired: true,
      actionMandatory: false,
      selectedTriggerNotification: [],
      selectedTriggerPlan: [],
      tags: [],
      images: [],
      response: [],
    };
    dispatch({
      type: UPDATE_PROP,
      prop: `survey.sections.${sectionindex}.subsections.${subsectionindex}.questions`,
      value: (v) => {
        return [...v, itemInfo];
      },
    });

    const questionIndex =
      getState().survey.sections[sectionindex].subsections[subsectionindex]
        .questions.length - 1;
    dispatch({
      type: UPDATE_PROP,
      prop: "survey.selectedQuestion",
      value: {
        ...getState().survey.sections[sectionindex].subsections[subsectionindex]
          .questions[questionIndex],
        sectionindex: sectionindex,
        subsectionindex: subsectionindex,
        questionIndex: questionIndex,
        sectionId: sectionId,
        subSectionId: subSectionId,
      },
    });
  };
};
export const addSectionAction = (text, callbackfn) => {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_PROP,
      prop: `survey.sections`,
      value: (v) => {
        return [
          ...v,
          {
            id: Math.floor(Math.random() * Date.now()),
            name: text,
            subsections: [],
          },
        ];
      },
    });
    console.log(getState().survey.sections);
    callbackfn();
  };
};
// const addSection = (text) => {
//   dispatch({
//     payload: [
//       {
//         type: UPDATE_PROP,
//         prop: `sections`,
//         value: (v) => {
//           return [
//             ...v,
//             {
//               id: Math.floor(Math.random() * Date.now()),
//               name: text,
//               subsections: [],
//             },
//           ];
//         },
//       },
//     ],
//   });
// };
