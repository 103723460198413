import styles from './Plans.module.css';
import Logo from '../../components/logo/Logo';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { AUTHENTICATION_TOKEN } from '../../helpers/constants/StaticKeys';
import { fetchLoggedinUser } from '../../api/services/UserServices';
import { useDispatch } from 'react-redux';
import { loginAction } from '../../store/Actions';
import { setUserToLS } from '../../helpers/Storage';
import PlanCard from './components/planCard';
import Spacer from '../../components/general/spacer/Spacer';
import useLogic from '../../helpers/hooks/use-logic';
import Logic, { INITIAL_STATE } from './logic';
import { UPDATE_PROP } from '../../store/ActionTypes';
import LoadingModal from '../../components/general/loading-modal/LoadingModal';
import AppModal from '../../components/general/app-modal/AppModal';
import AppButton from '../../components/general/app-button/AppButton';
import TableLayout from '../../components/general/table-layout/TableLayout';
import ConfirmModal from '../../components/general/confirm-modal/ConfrimModal';
const Plans = ({ navigation }) => {
  const { t } = useTranslation();
  const {
    state,
    updateProps,
    updateState,
    handleSubmit,
    columns,
    data,
    setData,
    error,
    HandelDelete,
    handleCloseDelete,
  } = useLogic({
    INITIAL_STATE,
    Logic,
  });
  const location = useLocation();
  const usedUsers = location.state?.usedUsersCount;
  const previousUsers = location.state?.usersNumber;
  console.log('display uses which he can renew', data);
  const { response, selectedPeriod, selectedPlan, loading } = state;
  // useEffect(() => {
  //   if (!state.showUserSelectionModal && state.users < usedUsers) {
  //     console.log("User selection modal closed, re-checking handleConfirm...");
  //     handleConfirm();
  //   }
  // }, [state.showUserSelectionModal]);
  const handleConfirm = () => {
    console.log('Before updateProps:', state.showConfirmModal);
  
    if (state.users <= usedUsers) { 
      updateProps([
        { prop: 'showUserSelectionModal', value: true, type: UPDATE_PROP },
        { prop: 'showConfirmModal', value: false, type: UPDATE_PROP },
      ]);
      console.log(" showUserSelectionModal opened");
    } else {
      updateProps([
        { prop: 'showUserSelectionModal', value: false, type: UPDATE_PROP },
        { prop: 'showConfirmModal', value: true, type: UPDATE_PROP },
      ]);
  
      requestAnimationFrame(() => {
        console.log("Now calling handleSubmit...");
        handleSubmit();
      });
    }
  };
  const handleCloseModal = () => {
    updateProps([
      { prop: 'showConfirmModal', value: false, type: UPDATE_PROP },
      { prop: 'showUserSelectionModal', value: false, type: UPDATE_PROP },
      { prop: 'loadingConfirm', value: false, type: UPDATE_PROP },
    ]);
  };
  return (
    <div className={styles.container}>
      <div className={styles.subContainer}>
        <div className={styles.header}>
          {/* <Logo classes={styles.logoSize} />
                    <div className={styles.slogan}>{t("slogan")}</div> */}
          <div style={{ marginBottom: 25 }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                // backgroundColor: "white",
                borderRadius: 5,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {response?.map((d, i) => {
                return (
                  <div
                    onClick={() => {
                      // updateState({ prop: "selectedPeriod", value: i });
                      updateProps([
                        {
                          prop: 'selectedPeriod',
                          value: i,
                          type: UPDATE_PROP,
                        },
                        {
                          prop: 'selectedPlan',
                          value: null,
                          type: UPDATE_PROP,
                        },
                      ]);
                    }}
                    style={{
                      border: '1px var(--primary) solid',
                      borderTopLeftRadius: i == 0 ? 5 : 0,
                      borderBottomLeftRadius: i == 0 ? 5 : 0,
                      borderTopRightRadius: i == response.length - 1 ? 5 : 0,
                      borderBottomRightRadius: i == response.length - 1 ? 5 : 0,
                      padding: '5px 10px',
                      backgroundColor:
                        selectedPeriod == i ? 'var(--primary)' : 'white',
                      color: selectedPeriod == i ? 'white' : 'var(--primary)',
                    }}
                  >
                    {d.name}
                  </div>
                );
              })}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            {response[selectedPeriod]?.plans?.map((d, i) => {
              return (
                <>
                  <PlanCard
                    plan={d}
                    selected={selectedPlan == i}
                    onPlanClick={() => {
                      updateProps([
                        {
                          type: UPDATE_PROP,
                          prop: 'selectedPlan',
                          value: i,
                        },
                        {
                          type: UPDATE_PROP,
                          prop: 'users',
                          value: 1,
                        },
                      ]);
                    }}
                    onChangeUser={(e) => {
                      let x = e.target.value;
                      if (x === '') {
                        updateProps([
                          {
                            type: UPDATE_PROP,
                            prop: 'users',
                            value: '',
                          },
                        ]);
                        return;
                      }

                      let num = parseInt(x, 10);
                      num = isNaN(num) ? 1 : num;
                      num = Math.max(1, Math.min(num, 9999));

                      updateProps([
                        {
                          type: UPDATE_PROP,
                          prop: 'users',
                          value: num,
                        },
                      ]);
                    }}
                    users={state.users}
                    total={
                      state.users * response[selectedPeriod].factor * d.price
                    }
                    totalPerText={response[selectedPeriod].name}
                    handleSubmit={() =>
                      updateProps([
                        {
                          type: UPDATE_PROP,
                          prop: 'showConfirmModal',
                          value: true,
                        },
                      ])
                    }
                  />
                  {i != response.length - 1 && <Spacer width={20} />}
                </>
              );
            })}
          </div>
        </div>
      </div>
      <LoadingModal show={loading.loadingSubmit || loading.loadingPlans} />
      <AppModal
        show={state.showConfirmModal}
        onHide={handleCloseModal}
        headerTitle="Confirm User Reduction"
        isLoading={state.loadingConfirm}
        header={
          <button
            onClick={handleCloseModal}
            style={{
              border: 'none',
              background: 'transparent',
              fontSize: '20px',
              cursor: 'pointer',
              position: 'absolute',
              right: '15px',
              top: '10px',
            }}
          >
            ✖
          </button>
        }
        footer={
          <>
            <AppButton
              className="primaryButton"
              style={{
                minWidth: '150px',
                padding: '12px 20px',
                fontSize: '16px',
              }}
              value={state.loadingConfirm ? '' : 'Confirm'}
              isLoading={state.loadingConfirm}
              onClick={handleConfirm}
              disabled={state.loadingConfirm}
            />

            {/* Confirm
            </AppButton> */}
          </>
        }
      >
        <p>
          You are selecting fewer users than your previous subscription. Are you
          sure you want to proceed?
        </p>
      </AppModal>
      <AppModal
        show={state.showUserSelectionModal}
        onHide={() =>
          updateProps([
            { prop: 'showUserSelectionModal', value: false, type: UPDATE_PROP },
          ])
        }
        headerTitle="Select Users to remove"
        footer={
          <>
            <AppButton
              className="secondaryButton"
              style={{
                minWidth: '150px',
                padding: '12px 20px',
                fontSize: '16px',
                marginRight: '10px',
                backgroundColor: '#ccc',
                color: '#000',
              }}
              value="Cancel"
              onClick={() => {
                updateProps([
                  {
                    prop: 'showUserSelectionModal',
                    value: false,
                    type: UPDATE_PROP,
                  },
                ]);
              }}
            />
            <AppButton
              className="primaryButton"
              style={{
                minWidth: '150px',
                padding: '12px 20px',
                fontSize: '16px',
              }}
              value="Confirm"
              onClick={() => {
                updateProps([
                  {
                    prop: 'showUserSelectionModal',
                    value: false,
                    type: UPDATE_PROP,
                  },
                  { prop: 'showDeleteAlert', value: true, type: UPDATE_PROP },
                  { prop: 'showConfirmModal', value: true, type: UPDATE_PROP },
                ]);
              }}
            />
          </>
        }
      >
        <div style={{ textAlign: 'center' }}>
          <p>
            You are renewing with fewer users than your current subscription.
            Please select the users you want to remove.
          </p>
          <p style={{ color: 'red', fontWeight: 'bold' }}>
            Be careful! You currently have {data.total} users, and you are
            renewing for {state.users}. You have selected{' '}
            {data.selectedRows.length} users to remove.
          </p>
        </div>

        <TableLayout
          tableColumns={columns}
          setData={setData}
          data={data}
          error={error}
          showCheckbox
        />
      </AppModal>
      {state.showDeleteAlert && (
        <ConfirmModal
          show={state.showDeleteAlert}
          onHide={() => handleCloseDelete()}
          onSave={() => HandelDelete()}
          isLoading={state.deleteLoading}
        />
      )}
    </div>
  );
};
export default Plans;
