import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { INITIAL_STATE } from "../../../../helpers/RtCommon";
import {
  addQuestionAction,
  addSectionAction,
  updateSections,
  updateState,
} from "../../../../store/Actions";
import {
  DELETE_PROP,
  UPDATE_PROP
} from "../../../../store/ActionTypes";
import { useTranslation } from "react-i18next";
const Logic = () => {
  const state = useSelector((state) => state.survey);
  const { t } = useTranslation();

  const assignees = useSelector((state) => {
    return { ...INITIAL_STATE, results: state.survey.surveyDetails.assignees };
  });
  const dispatch = useDispatch();

  const setSelectedQuestion = (item) => {
    // console.log("===========setSelectedQuestion=========================");

    // console.log(item);

    console.log(
      "======before set selected question =============================="
    );
    console.log(state);
    dispatch({
      type: UPDATE_PROP,
      prop: "survey.selectedQuestion",
      value: item,
    });
    console.log(
      "======after set selected question =============================="
    );
    console.log(state);
  };
  const toggleSection = (index) => {
    dispatch({
      type: UPDATE_PROP,
      prop: `survey.sections.${index}`,
      value: (v) => {
        console.log(v);
        return {
          ...v,
          collapsed: !v.collapsed,
        };
      },
    });
  };
  const toggleSubSection = (sectionindex, subsectionindex) => {
    dispatch({
      type: UPDATE_PROP,
      prop: `survey.sections.${sectionindex}.subsections.${subsectionindex}`,
      value: (v) => {
        console.log(v);
        return {
          ...v,
          collapsed: !v.collapsed,
        };
      },
    });
  };
  const deleteSection = (index) => {
    if (
      state.selectedQuestion &&
      state.selectedQuestion.sectionId === state.sections[index].id
    ) {
      console.log("deleteSection");
      dispatch({
        type: UPDATE_PROP,
        prop: "survey.selectedQuestion",
        value: null,
      });
    }
    dispatch({
      type: DELETE_PROP,
      prop: `survey.sections.${index}`,
    });
  };
  const deleteSubSection = (sectionindex, subsectionindex) => {
    if (
      state.selectedQuestion &&
      state.selectedQuestion.subSectionId ===
        state.sections[sectionindex].subsections[subsectionindex].id
    ) {
      console.log("deleteSubSection");
      dispatch({
        type: UPDATE_PROP,
        prop: "survey.selectedQuestion",
        value: null,
      });
    }
    dispatch({
      type: DELETE_PROP,
      prop: `survey.sections.${sectionindex}.subsections.${subsectionindex}`,
    });
  };
  const deleteQuestion = (sectionindex, subsectionindex, questionIndex) => {
    if (
      state.selectedQuestion &&
      state.selectedQuestion.id ===
        state.sections[sectionindex].subsections[subsectionindex].questions[
          questionIndex
        ].id
    ) {
      console.log("deleteQuestion");
      dispatch({
        type: UPDATE_PROP,
        prop: "survey.selectedQuestion",
        value: null,
      });
    }
    dispatch({
      type: DELETE_PROP,
      prop: `survey.sections.${sectionindex}.subsections.${subsectionindex}.questions.${questionIndex}`,
    });
  };

  // create a function to duplicate the question
  const duplicateQuestion = (sectionindex, subsectionindex, questionIndex) => {
    // console.log("duplicateQuestion");
    const question =
      state.sections[sectionindex].subsections[subsectionindex].questions[
        questionIndex
      ];
    const newQuestion = {
      ...question,
      id: Math.floor(Math.random() * Date.now()),
    };
    dispatch({
      type: UPDATE_PROP,
      prop: `survey.sections.${sectionindex}.subsections.${subsectionindex}.questions`,
      value: (v) => [...v, newQuestion],
    });

    // set selected question
    dispatch({
      type: UPDATE_PROP,
      prop: "survey.selectedQuestion",
      value: {
        ...newQuestion,
        sectionindex: sectionindex,
        subsectionindex: subsectionindex,
        questionIndex:
          state.sections[sectionindex].subsections[subsectionindex].questions
            .length,
        sectionId: state.sections[sectionindex].id,
        subSectionId:
          state.sections[sectionindex].subsections[subsectionindex].id,
      },
    });
    console.info(question, newQuestion);
  };

  const addSubSection = (index, text) => {
    dispatch({
      type: UPDATE_PROP,
      prop: `survey.sections.${index}.subsections`,
      value: (v) => {
        return [
          ...v,
          {
            id: Math.floor(Math.random() * Date.now()),
            name: text,
            questions: [],
          },
        ];
      },
    });
  };

  // duplicate subsection
  const duplicateSubSection = (sectionindex, subsectionindex) => {
    const subsection =
      state.sections[sectionindex].subsections[subsectionindex];
    const newSubSectionId = Math.floor(Math.random() * Date.now());
    const newSubSection = {
      ...subsection,
      id: newSubSectionId,
    };
    console.error(newSubSection.questions);
    // change question ids
    newSubSection.questions = newSubSection.questions.map((question) => {
      return {
        ...question,
        id: Math.floor(Math.random() * Date.now()),
      };
    });

    dispatch({
      type: UPDATE_PROP,
      prop: `survey.sections.${sectionindex}.subsections`,
      value: (v) => [...v, newSubSection],
    });
    console.log(subsection, newSubSection);
  };
  const addQuestion = (
    sectionindex,
    subsectionindex,
    text,
    sectionId,
    subSectionId,
  ) => {
    // console.log("add question");
    dispatch(
      addQuestionAction(
        sectionindex,
        subsectionindex,
        text,
        sectionId,
        subSectionId,
      )
    );
    // dispatch({
    //     type: UPDATE_PROP,
    //     prop: `survey.sections.${sectionindex}.subsections.${subsectionindex}.questions`,
    //     value: (v) => {
    //         return [
    //             ...v,
    //             {
    //                 id: Math.floor(Math.random() * Date.now()),
    //                 name: text,
    //                 type: "number",
    //                 questionHelp: "",
    //                 mandatory: true,
    //                 enabled: true,
    //                 showImage: "",
    //                 printSize: "",
    //                 selectEmailAddress: [],
    //                 notificationEmailRequired: true,
    //                 actionNotificationEmailRequired: false,
    //                 actionMandatory: false,
    //                 selectedTriggerNotification: [],
    //                 selectedTriggerPlan: [],
    //                 tags: [],
    //                 images: [],
    //                 response: [],
    //             },
    //         ];
    //     },
    // });
    // setSelectedQuestionTemp({ sectionId, subSectionId, sectionindex, subsectionindex });
  };

  const updateQuestion = (optionsPayload) => {
    let payload = [];
    let propPrefix = `survey.sections.${state.selectedQuestion.sectionindex}.subsections.${state.selectedQuestion.subsectionindex}.questions.${state.selectedQuestion.questionIndex}`;
    optionsPayload.forEach((element) => {
      payload.push({
        type: element.type,
        prop: propPrefix + `.${element.prop}`,
        value: element.value,
      });
    });
    dispatch(updateState(payload));
  };


  const addSection = (text, callbackfn) => {
    console.log("add");
    dispatch(addSectionAction(text, callbackfn));
  };
  const cleanTranslations = (translations) => {
    if (translations?.name?.ar || translations?.name?.en) {
      return {
        name: translations.name?.en || translations.name?.ar || translations.name, 
        translations: {
          en: translations.name?.en || translations.en,
          ar: translations.name?.ar || translations.ar,
        },
      };
      
    }
    return translations;
  };
  
  const updateSectionName = (index, name, translations) => {
    const updatedTranslations = {
      ...translations,
      [t("langCode")]: name,
    };
    console.log("Updated Translations:", translations);

    const payload = [
      {
        type: UPDATE_PROP,
        prop: `survey.sections.${index}.name`,
        value: name,
      },
      {
        type: UPDATE_PROP,
        prop: `survey.sections.${index}.translations.sectionName`,
        value: updatedTranslations,
      },
    ];
  
    dispatch(updateState(payload));
  };
  
  const updateSectionTranslations = (index, translations) => {
    // const cleanedTranslations = cleanTranslations(translations);
    console.log("Before updating Redux:", translations);
    const payload = [
      {
        type: UPDATE_PROP,
        prop: `survey.sections.${index}.translations.sectionName`,
        value: translations,
      },
      {
        type: UPDATE_PROP,
        prop: `survey.sections.${index}.name`,
        value: translations[t("langCode")],
      },
    ];
  
    dispatch(updateState(payload));
  };
  
  
  const updateSubsectionName = (sectionIndex, index, name, translations) => {
    console.log("-----------------------");
    console.log("Section Index:", sectionIndex, "Subsection Index:", index, "Name:", name);
  
    // const cleanedTranslations = cleanTranslations(translations);
  
    const updatedTranslations = {
      ...translations,
      [t("langCode")]: name, 
    };
  
    const payload = [
      {
        type: UPDATE_PROP,
        prop: `survey.sections.${sectionIndex}.subsections.${index}.name`,
        value: name,
      },
      {
        type: UPDATE_PROP,
        prop: `survey.sections.${sectionIndex}.subsections.${index}.translations.subsectionName`,
        value: updatedTranslations,
      },
    ];
    dispatch(updateState(payload));
  };
  
  const updateSubSectionTranslations = (sectionIndex, index, translations) => {
    // const cleanedTranslations = cleanTranslations(translations);
  
    const payload = [
      {
        type: UPDATE_PROP,
        prop: `survey.sections.${sectionIndex}.subsections.${index}.translations.subsectionName`,
        value: translations,
      },
      {
        type: UPDATE_PROP,
        prop: `survey.sections.${sectionIndex}.subsections.${index}.name`,
        value: translations[t("langCode")], 
      },
    ];
    dispatch(updateState(payload));
  };
  
  const findItemIndexById = (items, id) => {
    return items.findIndex((item) => item.id === id);
  };

  const handleCheck = (event, prop, arr) => {
    var updatedList = [...arr];
    if (event.target.checked) {
      updatedList = [...arr, event.target.value];
    } else {
      updatedList.splice(arr.indexOf(event.target.value), 1);
    }
    let propPrefix = `survey.sections.${state.selectedQuestion.sectionindex}.subsections.${state.selectedQuestion.subsectionindex}.questions.${state.selectedQuestion.questionIndex}`;
    dispatch({
      type: UPDATE_PROP,
      value: updatedList,
      prop: propPrefix + `.${prop}`,
    });
  };

  const getQuestionInfo = useCallback(() => {
    const { id, sectionId, subSectionId } = state.selectedQuestion;
    const sectionIndex = findItemIndexById(state.sections, sectionId);
    const subsectionIndex = findItemIndexById(
      state.sections[sectionIndex].subsections,
      subSectionId
    );
    const questionIndex = findItemIndexById(
      state.sections[sectionIndex].subsections[subsectionIndex].questions,
      id
    );
    return state.sections[sectionIndex].subsections[subsectionIndex].questions[
      questionIndex
    ];
  }, [state.sections, state.selectedQuestion]);

  const onSectionDrop = (dropResult) => {
    let sections = [...state.sections];
    sections = applyDrag(sections, dropResult);
    dispatch(updateSections(sections));
  };

  // drag and drop subsections
  const onSubsectionDrop = (sectionId, dropResult) => {
    if (dropResult.removedIndex !== null || dropResult.addedIndex !== null) {
      const sections = [...state.sections];
      const section = sections.filter((p) => p.id === sectionId)[0];
      const sectionIndex = sections.indexOf(section);

      const newSection = Object.assign({}, section);
      newSection.subsections = applyDrag(newSection.subsections, dropResult);
      sections.splice(sectionIndex, 1, newSection);
      dispatch(updateSections(sections));
    }
  };

  const getPayload = (item) => {
    // alert('aah')
    // console.log(item);
    // console.log('====================================');
    setSelectedQuestion(null);
    return item;
  };

  const applyDrag = useCallback((arr, dragResult) => {
    const { removedIndex, addedIndex, payload } = dragResult;
    if (removedIndex === null && addedIndex === null) return arr;
    const result = [...arr];
    let itemToAdd = payload;

    if (removedIndex !== null) {
      console.log("remove");
      itemToAdd = result.splice(removedIndex, 1)[0];
    }

    if (addedIndex !== null) {
      console.log("add");
      result.splice(addedIndex, 0, itemToAdd);
    }
    console.log(result);

    return result;
  }, []);

  const onQuestionDrop = (secId, subId, dropResult) => {
    if (dropResult.removedIndex !== null || dropResult.addedIndex !== null) {
      const sections = [...state.sections];
      const section = sections.filter((p) => p.id === secId)[0];
      const sectionIndex = sections.indexOf(section);
      const subsection = section.subsections.filter((p) => p.id === subId)[0];
      const subsectionIndex = section.subsections.indexOf(subsection);

      const newSubsectionIndex = Object.assign({}, subsection);
      newSubsectionIndex.questions = applyDrag(newSubsectionIndex.questions, {
        ...dropResult,
      });
      section.subsections.splice(subsectionIndex, 1, newSubsectionIndex);
      dispatch(updateSections(sections));
    }
  };
  const setQuestionsImages = (image, index) => {
    console.log("setQuestionsImages : ", { image, index });
    const item = {};
    item[index] = image;
    dispatch({
      type: UPDATE_PROP,
      prop: "survey.images",
      value: (v) => {
        return { ...v, ...item };
      },
    });
  };

  return {
    state,
    addSubSection,
    addQuestion,
    addSection,
    deleteSection,
    deleteSubSection,
    deleteQuestion,
    toggleSection,
    toggleSubSection,
    setSelectedQuestion,
    updateQuestion,
    handleCheck,
    findItemIndexById,
    getQuestionInfo,
    onSectionDrop,
    onSubsectionDrop,
    getPayload,
    onQuestionDrop,
    assignees,
    dispatch,
    setQuestionsImages,
    updateSectionName,
    updateSectionTranslations,
    cleanTranslations,
    updateSubSectionTranslations,
    updateSubsectionName,
    duplicateQuestion,
    duplicateSubSection,
    // data,
    // setData,
  };
};
export default Logic;
