import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ConfirmModal from "../../../../components/general/confirm-modal/ConfrimModal";
import TableLayout from "../../../../components/general/table-layout/TableLayout";
import useLogic from "../../../../helpers/hooks/use-logic";
import { UPDATE_PROP } from "../../../../store/ActionTypes";
import { INITIAL_STATE as initalSurvey } from "../../../../store/Constants";
import Logic, { INITIAL_STATE } from "./logic";

const Checklists = () => {
  const { t } = useTranslation();
  const filterData = [
    {
      key: "audit_templates.name",
      label: t("audit"),
      sign: [{ value: "contains", label: t("contains") }],
    },
    //Department needs a further look in the backend as its not being saved correctly
    //when creating a new checklist
    // {
    //   key: "department",
    //   label: "Department",
    //   sign: [{ value: "contains", label: "contains" }],
    // },

    // ... other filters
  ];

  const filterKeys = {
    "audit_templates.name": {
      type: "string",
      dropdown: false,
      sign: [
        { value: "contains", label: t("contains") },
        { value: "is_equal", label: t("equals") },
        // ... other signs
      ],
    },
    // department: {
    //   type: "string",
    //   dropdown: false,
    //   sign: [
    //     { value: "contains", label: "Contains" },
    //     { value: "is_equal", label: "Equals" },
    //     // ... other signs
    //   ],
    // },
  };
  let navigate = useNavigate();
  const {
    state,
    updateState,
    data,
    setData,
    error,
    showDeleteAlert,
    HandleCloseDelete,
    HandelDelete,
    columns,
  } = useLogic({
    INITIAL_STATE,
    Logic,
  });

  const dispatch = useDispatch();
  return (
    <>
      <TableLayout
        tableColumns={columns}
        setData={setData}
        title={t("audits")}
        showButton={true}
        data={data}
        error={error}
        buttonValue={t("new")}
        buttonIcon={
          <FontAwesomeIcon icon={faPlus} style={{ margin: "0px 5px" }} />
        }
        buttonOnClick={() => {
          dispatch({
            type: UPDATE_PROP,
            prop: "survey",
            value: (v) => {
              return {
                ...v,
                id: null,
                surveyDetails: {
                  ...initalSurvey.survey.surveyDetails,
                },
                sections: [
                  {
                    id: 1,
                    name: "Section 1",
                    collapsed: false,
                    subsections: [
                      {
                        id: 1,
                        name: "Subsection 1",
                        collapsed: false,
                        questions: [],
                      },
                    ],
                  },
                ],
                selectedQuestion: null,
              };
            },
          });
          navigate("/portal/form-builder");
        }}
        buttonStyle={{ color: "white" }}
        buttonClassName="primaryButton"
        filterData={{ filterData }}
        filterKeys={{ filterKeys }}
        showFilter={true}
      />
      {state.showDeleteAlert && (
        <ConfirmModal
          show={state.showDeleteAlert}
          onHide={() => HandleCloseDelete()}
          onSave={() => HandelDelete()}
          isLoading={state.deleteLoading}
        />
      )}
    </>
  );
};
export default Checklists;
