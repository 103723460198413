import { faCircleNotch, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import AppButton from '../../../../../../components/general/app-button/AppButton';
import AppInput from '../../../../../../components/general/app-input/AppInput';
import AppSelect from '../../../../../../components/general/app-select/AppSelect';
import Table from '../../../../../../components/general/app-table/AppTable';
import ConfirmModal from '../../../../../../components/general/confirm-modal/ConfrimModal';
import Label from '../../../../../../components/general/label/Label';
import SwitchButton from '../../../../../../components/general/switch-button/SwitchButton';
import Title from '../../../../../../components/general/title/Title';
import ValidationProvider from '../../../../../../components/general/validation-provider/ValidationProvider';
import useLogic from '../../../../../../helpers/hooks/use-logic';
import { UPDATE_PROP } from '../../../../../../store/ActionTypes';
import { filterSelectStyles } from '../structure/Constants';
import Styles from './Basic.module.css';
import AddAssigneeModal from './components/add-assignee-modal/AddAssigneeModal';
import Logic, { INITIAL_STATE } from './logic';
import { useEffect, useRef, useState } from 'react';
import TranslationModal from '../../../../../../components/general/translation-modal/TranslationModal';
import RadioInput from '../../../../../../components/general/radio-input/RadioInput';
import CreatableSelect from 'react-select/creatable';

const Basic = ({ id, onChange, onBlur, errors }) => {
  const { t } = useTranslation();
  const translationModalRef = useRef();
  const [currentField, setCurrentField] = useState({});

  const {
    state,
    reduxState,
    updateState,
    handleClick,
    HandleCloseDelete,
    HandelDelete,
    generateSrc,
    hiddenFileInput,
    columns,
    // saveAuditTemplate,
    updateAuditDetails,
    assignees,
    navigate,
    onchange,
    handleUpdateOrCreate,
  } = useLogic({
    INITIAL_STATE,
    Logic,
    prop: { id },
  });
  useEffect(() => {
    if (!reduxState.surveyDetails?.visible_condition) {
      handleCheckboxChange('anytime');
    }
    // console.log('Visible condition is:', reduxState.surveyDetails);
  }, [reduxState.surveyDetails?.visible_condition]);

  // console.log('Current selected value:', reduxState.masterData.departments);
  const handleCheckboxChange = (field) => {
    const selectedValue = field;

    // console.log('handleCheckboxChange called with:', field);
    // console.log('Selected value:', selectedValue);

    updateAuditDetails([
      {
        type: UPDATE_PROP,
        prop: 'surveyDetails.visible_condition',
        value: selectedValue,
      },
    ]);
  };

  return (
    <div>
      <div className={Styles.formContainer}>
        <div className={Styles.sectionGroup}>
          <div
            className={Styles.sectionContainer}
            style={{ paddingTop: '5px' }}
          >
            <div className={Styles.groupControl}>
              <ValidationProvider
                error={errors.name}
                render={({ requiredTitle }) => (
                  <Label
                    name={requiredTitle(t('name'))}
                    className={Styles.label}
                  >
                    <AppInput
                      type="text"
                      className={Styles.input}
                      onChange={(e) => {
                        const updatedValue = e.target.value;
                        onChange('name', updatedValue);
                        let updatedTranslations = {
                          ...reduxState.surveyDetails.translations,
                          name: {
                            ...reduxState.surveyDetails.translations['name'],
                            [t('langCode')]: updatedValue,
                          },
                        };
                        updateAuditDetails([
                          {
                            type: UPDATE_PROP,
                            prop: 'surveyDetails.translations',
                            value: updatedTranslations,
                          },
                        ]);

                        console.log('Updated input value:', updatedValue);
                        console.log(
                          'Synchronized translations:',
                          updatedTranslations,
                        );
                      }}
                      onBlur={(e) => onBlur('name')}
                      value={
                        // Display the value based on the current language dynamically
                        reduxState.surveyDetails.translations['name']?.[
                          t('langCode')
                        ] || reduxState.surveyDetails.name
                      }
                      onTranslation={() => {
                        setCurrentField({
                          column: 'name',
                          translations:
                            reduxState.surveyDetails.translations?.['name'] ||
                            {},
                        });

                        console.log(
                          'Current field before opening modal:',
                          reduxState.surveyDetails.translations['name'],
                        );
                        translationModalRef?.current?.toggleModal();
                      }}
                    />
                  </Label>
                )}
              />
            </div>
            {/* <div className={Styles.groupControl}>
                            <ValidationProvider
                                error={errors.short_name}
                                render={({ requiredTitle }) => (
                                    <Label
                                        name={requiredTitle(t("shortName"))}
                                        className={Styles.label}
                                    >
                                        <AppInput
                                            type="text"
                                            className={Styles.input}
                                            onChange={(e) => onChange("short_name", e.target.value)}
                                            onBlur={(e) => onBlur("short_name")}
                                            value={reduxState.surveyDetails.short_name}
                                        />
                                    </Label>
                                )}
                            />
                        </div> */}
            {/* <p>{JSON.stringify(reduxState.visible_condition)}</p> */}

            <div className={Styles.groupControl}>
              <Label name={t('visibleToAuditor')} className={Styles.label}>
                <div
                  style={{
                    display: 'flex',
                    gap: '10px',
                    padding: '7px',
                    justifyContent: 'space-around',
                  }}
                >
                  {['today', 'tomorrow', 'anytime'].map((field) => {
                    // console.log('Rendering RadioInput for field:', field);
                    console.log(
                      'Current selected value:',
                      reduxState.surveyDetails?.visible_condition,
                    );

                    return (
                      <RadioInput
                        key={field}
                        name="visible_condition"
                        value={field}
                        label={t(
                          field === 'today'
                            ? 'onTheDay'
                            : field === 'tomorrow'
                              ? 'dayAfter'
                              : 'anytime',
                        )}
                        isChecked={
                          reduxState.surveyDetails?.visible_condition === field
                        }
                        handleChange={() => handleCheckboxChange(field)}
                      />
                    );
                  })}
                </div>
              </Label>
            </div>
            <div className={Styles.groupControl}>
              {/* <ValidationProvider
                error={errors.department_id}
                render={({ requiredTitle }) => ( */}
              <Label name={t('department')} className={Styles.label}>
                {state.isLoading ? (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px',
                    }}
                  >
                    <FontAwesomeIcon icon={faCircleNotch} spin />
                  </div>
                ) : (
                  <CreatableSelect
                    options={reduxState.masterData.departments.map(
                      (element) => ({
                        value: element.id,
                        label: element.name,
                      }),
                    )}
                    isSearchable={true}
                    value={reduxState.surveyDetails.department_id}
                    styles={filterSelectStyles}
                    isDisabled={state.isLoading}
                    placeholder="Select a department"
                    onChange={(e) => {
                      console.log('Selected department:', e);
                      const departmentExists =
                        reduxState.masterData.departments.some(
                          (dept) => dept.id === e.value,
                        );
                      console.log('display', departmentExists);

                      if (departmentExists) {
                        onChange('department_id', e);
                      } else {
                        handleUpdateOrCreate(e.label, (newId) => {
                          console.log('New department created with ID:', newId);
                          const newDepartment = {
                            value: newId,
                            label: e.label,
                          };
                          onChange('department_id', newDepartment);
                        });
                      }
                    }}
                    onBlur={() => onBlur('department_id')}
                  />
                )}
              </Label>
              {/* )}
              /> */}
            </div>
          </div>
          <div className={Styles.sectionContainer}>
            <ValidationProvider
              error={errors.image}
              render={({ requiredTitle }) => (
                <>
                  <Title
                    title={t('logo')}
                    style={{ color: 'black', fontSize: '12px' }}
                  />

                  <div
                    style={{ display: 'flex', alignItems: 'center' }}
                    onBlur={() => onBlur('image')}
                  >
                    <AppButton
                      classes="buttonIcon"
                      style={{
                        color: 'white',
                        background: '#b8b8b8',
                        margin: '5px 10px 5px 0px',
                        padding: '26px 27px',
                        fontSize: '22px',
                        borderRadius: '6px',
                      }}
                      onClick={handleClick}
                      icon={<FontAwesomeIcon icon={faPlus} />}
                    />
                    {reduxState.surveyDetails.image && (
                      <img
                        src={generateSrc(reduxState.surveyDetails.image)}
                        width={150}
                        height={150}
                      />
                    )}

                    <AppInput
                      ref={hiddenFileInput}
                      type="file"
                      onChange={(e) => {
                        onChange('image', e.target.files[0]);
                      }}
                      inputStyle={{ minHeight: '30px', display: 'none' }}
                    />
                  </div>
                </>
              )}
            />
          </div>
        </div>
        <div className={Styles.sectionGroup}>
          <div className={Styles.sectionContainer}>
            <Title
              title={t('auditorSign')}
              border={true}
              style={{ color: 'black', fontSize: '14px' }}
            />
            <div
              style={{
                padding: '10px 5px',
                padding: '0px 5px',
                background: '#f7f7f7',
              }}
            >
              <div className={Styles.switchGroup}>
                <div className={Styles.switchControl}>
                  <SwitchButton
                    checked={!!reduxState.surveyDetails.show_sign}
                    onChange={() => {
                      updateAuditDetails([
                        {
                          type: UPDATE_PROP,
                          prop: `surveyDetails.show_sign`,
                          value: !reduxState.surveyDetails.show_sign,
                        },
                      ]);
                    }}
                    label={t('showInApp')}
                  />
                </div>
                {!!reduxState.surveyDetails.show_sign && (
                  <div className={Styles.switchControl}>
                    <SwitchButton
                      checked={!!reduxState.surveyDetails.sign_required}
                      onChange={() => {
                        updateAuditDetails([
                          {
                            type: UPDATE_PROP,
                            prop: `surveyDetails.sign_required`,
                            value: !reduxState.surveyDetails.sign_required,
                          },
                        ]);
                      }}
                      label={t('mandatory')}
                    />
                  </div>
                )}
              </div>
              {!!reduxState.surveyDetails.show_sign && (
                <div className={Styles.groupControl}>
                  <ValidationProvider
                    error={errors.sign_label}
                    render={({ requiredTitle }) => (
                      <Label
                        name={requiredTitle(t('sign_label'))}
                        className={Styles.label}
                      >
                        <AppInput
                          type="text"
                          className={Styles.input}
                          onChange={(e) => {
                            const updatedValue = e.target.value;
                            onChange('sign_label', updatedValue);
                            let updatedTranslations = {
                              ...reduxState.surveyDetails.translations,
                              sign_label: {
                                ...reduxState.surveyDetails.translations?.[
                                  'sign_label'
                                ],
                                [t('langCode')]: updatedValue,
                              },
                            };
                            updateAuditDetails([
                              {
                                type: UPDATE_PROP,
                                prop: 'surveyDetails.translations',
                                value: updatedTranslations,
                              },
                            ]);

                            console.log('Updated input value:', updatedValue);
                            console.log(
                              'Synchronized translations:',
                              updatedTranslations,
                            );
                          }}
                          onBlur={(e) => onBlur('sign_label')}
                          value={
                            reduxState.surveyDetails.translations?.[
                              'sign_label'
                            ]?.[t('langCode')] || ''
                          }
                          onTranslation={() => {
                            setCurrentField({
                              column: 'sign_label',
                              translations:
                                reduxState.surveyDetails.translations?.[
                                  'sign_label' || {}
                                ],
                            });
                            translationModalRef?.current?.toggleModal();
                          }}
                        />
                      </Label>
                    )}
                  />
                </div>
              )}
            </div>
          </div>
          <div className={Styles.sectionContainer}>
            <Title
              title={t('inChargeSign')}
              border={true}
              style={{ color: 'black', fontSize: '14px' }}
            />
            <div
              style={{
                padding: '10px 5px',
                padding: '0px 5px',
                background: '#f7f7f7',
              }}
            >
              <div className={Styles.switchGroup}>
                <div className={Styles.switchControl}>
                  <SwitchButton
                    checked={!!reduxState.surveyDetails.show_incharge_sign}
                    onChange={() => {
                      updateAuditDetails([
                        {
                          type: UPDATE_PROP,
                          prop: `surveyDetails.show_incharge_sign`,
                          value: !reduxState.surveyDetails.show_incharge_sign,
                        },
                      ]);
                    }}
                    label={t('showInApp')}
                  />
                </div>
                {!!reduxState.surveyDetails.show_incharge_sign && (
                  <div className={Styles.switchControl}>
                    <SwitchButton
                      checked={
                        !!reduxState.surveyDetails.incharge_sign_required
                      }
                      onChange={() => {
                        updateAuditDetails([
                          {
                            type: UPDATE_PROP,
                            prop: `surveyDetails.incharge_sign_required`,
                            value:
                              !reduxState.surveyDetails.incharge_sign_required,
                          },
                        ]);
                      }}
                      label={t('mandatory')}
                    />
                  </div>
                )}
              </div>
              {!!reduxState.surveyDetails.show_incharge_sign && (
                <div className={Styles.groupControl}>
                  <ValidationProvider
                    error={errors.incharge_sign_label}
                    render={({ requiredTitle }) => (
                      <Label
                        name={requiredTitle(t('incharge_sign_label'))}
                        className={Styles.label}
                      >
                        <AppInput
                          type="text"
                          className={Styles.input}
                          onChange={(e) => {
                            const updatedValue = e.target.value;
                            console.log('Value Changed:', updatedValue);
                            onChange('incharge_sign_label', updatedValue);
                            let updatedTranslations = {
                              ...reduxState.surveyDetails.translations,
                              incharge_sign_label: {
                                ...reduxState.surveyDetails.translations?.[
                                  'incharge_sign_label'
                                ],
                                [t('langCode')]: updatedValue,
                              },
                            };
                            updateAuditDetails([
                              {
                                type: UPDATE_PROP,
                                prop: 'surveyDetails.translations',
                                value: updatedTranslations,
                              },
                            ]);

                            console.log(
                              'Synchronized translations (EN & AR):',
                              updatedTranslations,
                            );
                          }}
                          onBlur={(e) => {
                            console.log('Blurred:', e.target.value);
                            onBlur('incharge_sign_label');
                          }}
                          value={
                            reduxState.surveyDetails.translations?.[
                              'incharge_sign_label'
                            ]?.[t('langCode')] || ''
                          }
                          onTranslation={() => {
                            const translationKey = 'incharge_sign_label';
                            console.log(
                              'Translation Triggered for:',
                              translationKey,
                            );
                            console.log(
                              'Translations:',
                              reduxState.surveyDetails.translations[
                                translationKey || {}
                              ],
                            );

                            setCurrentField({
                              column: translationKey,
                              translations:
                                reduxState.surveyDetails.translations[
                                  translationKey
                                ] || {},
                            });

                            translationModalRef?.current?.toggleModal();
                          }}
                        />
                      </Label>
                    )}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={Styles.sectionContainer} style={{ paddingTop: '20px' }}>
          <div className={Styles.title}>
            <Title
              title={t('responsibleUsers')}
              border={true}
              style={{ color: 'black', fontSize: '15px' }}
            >
              <AppButton
                className="primaryButton"
                style={{
                  borderRadius: 0,
                  borderTopRightRadius: '6px',
                  borderTopLeftRadius: '6px',
                  padding: '2px 10px',
                  borderBottom: 'none',
                  cursor: 'pointer',
                  lineHeight: 1,
                  fontSize: '13px',
                }}
                onClick={() => {
                  updateState({
                    type: UPDATE_PROP,
                    prop: 'addAssigneeModal',
                    value: true,
                  });
                }}
                value={t('add')}
                icon={
                  <FontAwesomeIcon icon={faPlus} style={{ margin: '0 5px' }} />
                }
              />
            </Title>
          </div>
          <div
            style={{
              padding: '0px 5px',
            }}
          >
            {/* <ValidationProvider
                            error={errors.assignees}
                            render={() => ( */}
            <Table columns={columns} data={assignees} showPagination={false} />
            {/* )}
                        /> */}
          </div>
        </div>
      </div>
      <div className={Styles.actionButton}>
        {/* <AppButton
          className="primaryButton"
          onClick={handleSubmit}
          value="Save"
          isLoading={state.saveLoading}
        />
        <AppButton
          className="whiteButton"
          onClick={() => {
            navigate("/forms-checklists");
          }}
          value="Cancel"
          style={{ margin: "0px 15px" }}
        /> */}
      </div>
      {state.addAssigneeModal && (
        <AddAssigneeModal
          show={state.addAssigneeModal}
          set={() => {
            updateState({
              type: UPDATE_PROP,
              prop: 'addAssigneeModal',
              value: false,
            });
          }}
          assigneeValue={state.assigneeValue}
          setAssigneeValue={() => {
            updateState({
              type: UPDATE_PROP,
              prop: 'assigneeValue',
              value: null,
            });
          }}
          updateAssignees={(value) => onChange('assignees', value)}
        />
      )}

      {state.showDeleteAlert && (
        <ConfirmModal
          show={state.showDeleteAlert}
          onHide={() => HandleCloseDelete()}
          onSave={() => HandelDelete()}
        />
      )}

      <TranslationModal
        ref={translationModalRef}
        onSave={(newObj) => {
          console.log(
            'Before saving translations:',
            reduxState.surveyDetails.translations,
          );

          let updatedTranslations = {
            ...reduxState.surveyDetails.translations,
            [newObj.column]: newObj.translations,
          };
          console.log(
            'Updated translations after modification:',
            updatedTranslations,
          );
          updateAuditDetails([
            {
              type: UPDATE_PROP,
              prop: 'surveyDetails.translations',
              value: updatedTranslations,
            },
            {
              type: UPDATE_PROP,
              prop: `surveyDetails.${newObj.column}`,
              value: newObj.translations.en,
            },
          ]);

          translationModalRef?.current?.toggleModal();

          console.log('Final translation object:', updatedTranslations);
        }}
        translationObj={currentField}
      />
    </div>
  );
};

export default Basic;
