import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppButton from "../../components/general/app-button/AppButton";
import { faBan } from "@fortawesome/free-solid-svg-icons";

export const UserColumn = (actions, email = '') => {
  const adminEmail = localStorage.getItem('admin');
  return [
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Actions',
      style: {
        textAlign: 'center',
      },
      maxWidth: 150,
      minWidth: 150,
      width: 150,
      Cell: ({ row: { values, original } }) => (
        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <AppButton
            classes="buttonIcon"
            style={{
              color: '#ff9800',
            }}
            onClick={() => actions.bulkDelete([original])}
            value="Deactivate"
            icon={
              <FontAwesomeIcon icon={faBan} style={{ margin: '0px 5px' }} />
            }
          />
        </div>
      ),
    },
  ];
};
