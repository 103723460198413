import React, { useState, useEffect } from 'react';
import Styles from './FormBuilder.module.css';
import Card from '../../../../components/general/card/Card';
import Basic from './components/basic/Basic';
import Structure from './components/structure/Structure';
import TabsLayout from '../../../../components/general/tabs-layout/TabsLayout';
import AppButton from '../../../../components/general/app-button/AppButton';
import Logic from './logic';
import { UPDATE_PROP } from '../../../../store/ActionTypes';
import LoadingSpinner from '../../../../components/general/loading-spinner/LoadingSpinner';
import useValidator from '../../../../helpers/hooks/use-validator';
import { useTranslation } from 'react-i18next';
import AppModal from '../../../../components/general/app-modal/AppModal';
import { useLocation, useNavigate } from 'react-router';
const FormBuilder = () => {
  const { t } = useTranslation();
  const [hasChanged, setHasChanges] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [nextPath, setNextPath] = useState(null);
  //   const navigate = useNavigate();
  const location = useLocation();
  const { reduxState, id, updateAuditDetails, saveAuditTemplate, navigate } =
    Logic();
  // console.log("typeof reduxState.surveyDetails.image === string");
  // console.log(typeof reduxState.surveyDetails.image === "string");
  const [closeOnSubmit, setCloseOnSubmit] = useState(false);
  useEffect(() => {
    if (!hasChanged) return;

    const handleOnBeforeUnload = (event) => {
      event.preventDefault();
    //   setShowModal(true);
      setNextPath(event.target.href);
    };

    window.addEventListener('beforeunload', handleOnBeforeUnload, {
      capture: true,
    });

    return () => {
      window.removeEventListener('beforeunload', handleOnBeforeUnload, {
        capture: true,
      });
    };
  }, [hasChanged]);
  useEffect(() => {
    const handleNavigation = (event) => {
      if (hasChanged) {
        event.preventDefault();
        setShowModal(true);
        setNextPath(event.target.href);
      }
    };

    document.querySelectorAll('a').forEach((link) => {
      link.addEventListener('click', handleNavigation);
    });

    return () => {
      document.querySelectorAll('a').forEach((link) => {
        link.removeEventListener('click', handleNavigation);
      });
    };
  }, [hasChanged]);
  useEffect(() => {
    const handlePopState = () => {
      if (hasChanged) {
        setShowModal(true);
        window.history.pushState(null, "", window.location.href); 
      }
    };
  
    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", handlePopState);
  
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [hasChanged]);
  
  const handleConfirmLeave = () => {
    setHasChanges(false);
    setShowModal(false);
    if (nextPath) navigate(nextPath);
  };
  const { errors, onBlur, onChange, handleSubmit } = useValidator({
    defaultValues: {
      ...reduxState.surveyDetails,
      sections: reduxState.sections,
    },
    setValue: (nextFormState) => {
      setHasChanges(true);
      // updateState({
      //   prop: "form",
      //   value: nextFormState,
      // });
      updateAuditDetails([
        {
          type: UPDATE_PROP,
          prop: `surveyDetails.name`,
          value: nextFormState.name,
        },
        {
          type: UPDATE_PROP,
          prop: `surveyDetails.short_name`,
          value: nextFormState.short_name,
        },
        {
          type: UPDATE_PROP,
          prop: `surveyDetails.department_id`,
          value: nextFormState.department_id,
        },
        {
          type: UPDATE_PROP,
          prop: `surveyDetails.image`,
          value: nextFormState.image,
        },
        {
          type: UPDATE_PROP,
          prop: `surveyDetails.assignees`,
          value: nextFormState.assignees,
        },
        {
          type: UPDATE_PROP,
          prop: `surveyDetails.sign_label`,
          value: nextFormState.sign_label,
        },
        {
          type: UPDATE_PROP,
          prop: `surveyDetails.incharge_sign_label`,
          value: nextFormState.incharge_sign_label,
        },
        {
          type: UPDATE_PROP,
          prop: `surveyDetails.translations`,
          value: nextFormState.translations,
        },
      ]);
    },
    onSubmit: (data) => {
        setHasChanges(false);
      saveAuditTemplate(data);
    },
    validations: {
      name: {
        required: true,
        validation_name: 'Audit name',
      },
      // short_name: { required: true, validation_name: "Short name" },
      // assignees: { required: true, validation_name: "Assignees" },
      image: {
        validation_name: 'Image',
        isImage: true,
        // required: typeof reduxState.surveyDetails.image === "string",
      },
      sections: { required: true, validation_name: 'Sections' },
      // department_id: { required: true, validation_name: "Department" },
      sign_label: {
        validate: () => {
          return (
            reduxState.surveyDetails.sign_required &&
            !!!reduxState.surveyDetails.sign_label.trim()
          );
        },
        validation_name: 'Sign label',
      },
      incharge_sign_label: {
        validate: () => {
          return (
            reduxState.surveyDetails.incharge_sign_required &&
            !!!reduxState.surveyDetails.incharge_sign_label.trim()
          );
        },
        validation_name: 'incharge sign label',
      },
    },
  });

  console.log('reduxState');
  console.log(reduxState);
  const tabsList = [
    {
      label: t('basic'),
      key: 'basic',
      renderContent: (
        <Card style={{ padding: '20px 10px' }}>
          <LoadingSpinner isLoading={reduxState.loading}>
            <Basic
              id={id}
              errors={errors}
              onChange={onChange}
              onBlur={onBlur}
            />
          </LoadingSpinner>
        </Card>
      ),
      required: true,
      error:
        errors.name.error ||
        errors.short_name.error ||
        errors.image.error ||
        errors.sign_label.error ||
        errors.incharge_sign_label.error,
    },
    {
      label: t('structure'),
      key: 'structure',
      renderContent: <Structure id={id} errors={errors} onBlur={onBlur} />,
      required: true,
      error: errors.sections.error,
    },
  ];
  return (
    <div className={Styles.container}>
      <TabsLayout
        tabsList={tabsList}
        title={t('formBuilder')}
        unmountOnExit={false}
        renderButton={
          <div style={{ display: 'flex' }}>
            <AppButton
              className="primaryButton"
              onClick={(e) => {
                handleSubmit(e, { closeOnSave: true });
              }}
              value={t('saveAndClose')}
              isLoading={reduxState.saveNCloseLoading}
              style={{ margin: '0px 10px' }}
            />
            <AppButton
              className="primaryButton"
              onClick={(e) => {
                handleSubmit(e, { closeOnSave: false });
              }}
              value={t('save')}
              isLoading={reduxState.saveLoading}
              style={{ margin: '0px 10px' }}
            />
            <AppButton
              className="whiteButton"
              onClick={() => {
                navigate('/portal/forms-checklists');
              }}
              value={t('cancel')}
            />
          </div>
        }
      />
      <AppModal
        show={showModal}
        onHide={() => setShowModal(false)}
        headerTitle="Confirmation"
        footer={
          <>
            <button
              className="btn btn-secondary"
              onClick={() => setShowModal(false)}
            >
              Stay
            </button>
            <button className="btn btn-danger" onClick={handleConfirmLeave}>
              Leave
            </button>
          </>
        }
      >
        <p>Are you sure you want to leave?</p>
      </AppModal>
    </div>
  );
};
export default FormBuilder;
